import React, { useEffect, useState } from "react";
import { ResponsivePie } from "@nivo/pie";
import { Spinner } from "reactstrap";

const TotaleSupply = () => {
  const Web3 = require("web3");

  const [data, setData] = useState([]);
  const [miningStats, setMiningStats] = useState({
    data: "...",
    loading: true,
  });

  useEffect(() => {
    getSummary();
  }, []);

  const getSummary = async () => {
    const urlMiningStats = "https://egold-miner.herokuapp.com/miningStats";

    //MiningStats
    fetch(urlMiningStats)
      .then(function (responseStats) {
        return responseStats.json();
      })
      .then(function (newStats) {
        setMiningStats({ data: newStats, loading: false });
      })
      .catch(function (error) {
        console.log("Requestfailed", error);
      });
  };

  const CenteredMetric = ({ centerX, centerY }) => {
    return (
      <>
        <text
          x={centerX}
          y={centerY - 12}
          textAnchor="middle"
          dominantBaseline="central"
          fill="black"
          style={{
            fontSize: "20px",
            fontWeight: 700,
          }}
        >
          Mining
        </text>
        <text
          x={centerX}
          y={centerY + 12}
          textAnchor="middle"
          dominantBaseline="central"
          fill="black"
          style={{
            fontSize: "20px",
            fontWeight: 700,
          }}
        >
          Capacity
        </text>
      </>
    );
  };

  useEffect(() => {
    if (miningStats.data !== "...") {
      setData([
        {
          id: "Mining Undeployed",
          label: "Mining Undeployed",
          value: noround(
            miningStats.data.miningRate.miningPower -
              miningStats.data.miningRate.miningDeployed,
            2
          ),
          color: "#ffc727",
        },
        {
          id: "Mining Deployed",
          label: "Mining Deployed",
          value: noround(miningStats.data.miningRate.miningDeployed, 2),
          color: "#4f6b75",
        },
      ]);
    }
  }, [miningStats]);

  //to not round values while showing balance
  function noround(val, x) {
    if (!val) return 0;
    var float = parseFloat(val).toFixed(18);
    var num = float.toString();
    var n = num.slice(0, num.indexOf(".") + (x + 1));
    return n;
  }
  function numFormatter(num) {
    if (num > 999 && num < 1000000) {
      return noround(num / 1000, 2) + "K"; // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
      return noround(num / 1000000, 2) + "M"; // convert to M for number from > 1 million
    } else if (num < 1000) {
      return num; // if value < 1000, nothing to do
    }
  }

  return (
    <>
      {miningStats.data == "..." || data.length == 0 ? (
        <div style={{ padding: "20px", textAlign: "center" }}>
          <Spinner />
        </div>
      ) : (
        <div className="chartbx">
          <div className="chbxhdiv">
            <ResponsivePie
              data={data}
              margin={
                window.innerWidth < 500
                  ? { top: 20, right: 20, bottom: 0, left: 20 }
                  : { top: 40, right: 80, bottom: 40, left: 80 }
              }
              valueFormat=" >-,"
              startAngle={-20}
              sortByValue={false}
              activeOuterRadiusOffset={8}
              enableArcLinkLabels={window.innerWidth < 500 ? false : true}
              enableArcLabels={false}
              arcLinkLabel={(e) => `${e.id}\n(${e.value})`}
              arcLinkLabelsTextColor="#333333"
              arcLinkLabelsThickness={2}
              arcLinkLabelsColor={{ from: "color" }}
              arcLabelsSkipAngle={10}
              arcLabelsTextColor="#ffffff"
              innerRadius={0.45}
              layers={[
                "arcs",
                "arcLabels",
                "arcLinkLabels",
                "legends",
                CenteredMetric,
              ]}
              colors={(slice) => slice.data.color}
            />
          </div>
          <div class="miningcapacity" id="miningCapacitySection">
            <div class="detailCard secpadding mb20">
              <div class="bardelshowp mb10">
                Total Metahash :{" "}
                {parseFloat(
                  noround(miningStats.data.miningRate.miningPower, 2)
                ).toLocaleString()}
              </div>
              <div class="bardelshowp mb10">
                <div class="primarybardot"></div>Metahash Undeployed :{" "}
                {parseFloat(
                  noround(
                    miningStats.data.miningRate.miningPower -
                      miningStats.data.miningRate.miningDeployed,
                    2
                  )
                ).toLocaleString()}
              </div>
              <div class="bardelshowp mb10">
                <div class="secondarybardot"></div>
                Metahash Deployed :{" "}
                {parseFloat(
                  noround(miningStats.data.miningRate.miningDeployed, 2)
                ).toLocaleString()}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default TotaleSupply;
