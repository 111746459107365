import React, { useEffect, useState } from "react";
import MinerCard from "./MinerCard/MinerCard";
import PillsMenu from "./Menu/PillsMenu";

import ConvertCard from "./EGConverter/ConvertCard"

export default function Buy() {
  const miners = [21, 22, 23, 24, 25, 26, 27, 28];
  return (
    <>
      <div class="mainsection">
        <ConvertCard />
        <div class="minertabsec secpadding lightgrey-bg brtlr">
          <PillsMenu activeval="buy" />
          <div class="tab-content" id="pills-tabContent">
            <div
              class="tab-pane fade show active"
              id="pills-buy"
              role="tabpanel"
              aria-labelledby="pills-buy-tab"
              tabindex="0"
            >
              <div class="tabcontent">
                <div class="row">
                  {miners.map((item, index) => (
                    <MinerCard type={item} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="lightblue-bg secpadding brblr mb20">
          <p class="text1">
            Welcome to Egold Miner Shop. Please select a miner from the list
            above to make a purchase. Each miner has a different mining power
            and price.
          </p>
        </div>
      </div>
    </>
  );
}
