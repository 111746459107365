import React, { useEffect, useState } from "react";
import BackButton from "./Buttons/BackButton/BackButton";
import { useNavigate, useParams } from "react-router-dom";
import CalcTables from "./ReferCalc/CalcTables";
import dataVal from "../data/Abis.json";



export default function RefCalculator() {
  let navigate = useNavigate();

  const Web3 = require("web3");
  const web3 = new Web3(process.env.REACT_APP_RPC);

  const [yourRank, setYourRank] = useState("...");
  const [downRank, setDownRank] = useState("...");
  const [saleMiner, setsaleMiner] = useState(21);
  const [payMethod, setPayMethod] = useState("busd");
  const [yourEarn, setYourEarn] = useState("...");
  const [gap, setGap] = useState("...");
  const [minerPrice, setMinerPrice] = useState("...");
  const [earnTokPrice, setEarnTokPrice] = useState("...");

  const updateValue = (your, down) => {
    if (!(your < down)) setGap(your - down);
  };

  useEffect(() => {
    if (!isNaN(saleMiner)) getMinerInfo(saleMiner);
  }, [saleMiner]);

  useEffect(() => {
    if (!isNaN(minerPrice)) getMinerTokPrice(minerPrice);
  }, [payMethod]);

  const getMinerInfo = async (minertype) => {
    const contractInstance = new web3.eth.Contract(
      dataVal.mregistryabi,
      process.env.REACT_APP_MINER_REGISTRY
    );

    await contractInstance.methods
      .fetchMinerInfo(minertype)
      .call()
      .then(async (res) => {
        setMinerPrice(parseInt(res.minerBaseRate));
        getMinerTokPrice(parseInt(res.minerBaseRate));
      });
  };

  const getMinerTokPrice = async (price) => {
    const routerInstance = new web3.eth.Contract(
      dataVal.pcrouterabi,
      process.env.REACT_APP_PANCAKE_ROUTER_ADDR
    );

    if (payMethod == "busd") {
      setEarnTokPrice(noround(price, 4));
    } else {
      if (payMethod == "egold") {
        var tokaddress = process.env.REACT_APP_EGOLD_ADDR;
        await routerInstance.methods
          .getAmountsIn(web3.utils.toWei(price.toString(), "ether"), [
            tokaddress,
            process.env.REACT_APP_BUSD_ADDR,
          ])
          .call()
          .then((data) => {
            setEarnTokPrice(noround(web3.utils.fromWei(data[0], "ether"), 4));
          });
      } else if (payMethod == "wbnb") {
        var tokaddress = process.env.REACT_APP_WBNB_ADDR;
        await routerInstance.methods
          .getAmountsOut(web3.utils.toWei(price.toString(), "ether"), [
            process.env.REACT_APP_BUSD_ADDR,
            tokaddress,
          ])
          .call()
          .then((data) => {
            setEarnTokPrice(noround(web3.utils.fromWei(data[1], "ether"), 4));
          });
      }
    }
  };

  function noround(number, decimalDigits) {
    const powerOfTen = Math.pow(10, decimalDigits);
    const formattedNumber = Math.floor(number * powerOfTen) / powerOfTen;
    if (Number.isInteger(formattedNumber)) {
      return roundzeroes(formattedNumber, 2);
    } else return formattedNumber;
  }

  //to round decimal points with zeroes
  function roundzeroes(val, x) {
    var float = parseFloat(val).toFixed(18);
    var num = float.toString();
    var n = num.slice(0, num.indexOf(".") + (x + 1));
    return n;
  }
  return (
    <>
      <div class="mainsection">
        <div class="tabsec secpadding lightgrey-bg brtlr">
          <BackButton Title="Referral Calculator" ReRoute="refer" />
          <div class="detailCard secpadding mb20">
            <div class="row">
              <div class="col-md-6">
                <label for="your_rank" class="form-label">
                  Your rank
                </label>
                <input
                  type="number"
                  class="form-control mb20"
                  id="your_rank"
                  placeholder="..."
                  value={yourRank}
                  onChange={(e) => {
                    setYourRank(e.target.value);
                  }}
                />
              </div>
              <div class="col-md-6">
                <label for="downline_rank" class="form-label">
                  Downline rank
                </label>
                <input
                  type="number"
                  class="form-control mb20"
                  id="downline_rank"
                  placeholder="..."
                  value={downRank}
                  onChange={(e) => {
                    setDownRank(e.target.value);
                  }}
                />
              </div>
            </div>
            <label for="downline_rank" class="form-label">
              Select Miner
            </label>
            <select
              class="form-select mb20"
              aria-label="Default select example"
              onChange={(e) => {
                setsaleMiner(e.target.value);
              }}
            >
               <option value="21"> X1</option>
              <option value="22"> X2</option>
              <option value="23"> X3</option>
              <option value="24"> X4</option>
              <option value="25"> X5</option>
              <option value="26"> X6</option>
              <option value="27"> X7</option>
              <option value="28"> X8</option>
            </select>
            <label for="downline_rank" class="form-label">
              Select payment method
            </label>
            <select
              class="form-select mb20"
              aria-label="Default select example"
              onChange={(e) => {
                setPayMethod(e.target.value);
              }}
            >
              <option value="busd">BUSD - BEP20</option>
              <option value="wbnb">WBNB - BEP20</option>
              <option value="egold">EGOLD - BEP20</option>
            </select>
            <div class="row">
              <div class="col-md-6">
                <label for="your_earning" class="form-label">
                  Your earning
                </label>
                <input
                  type="text"
                  class="form-control mb20"
                  id="your_earning"
                  placeholder="..."
                  value={
                    minerPrice == "..." || gap == "..." || earnTokPrice == "..."
                      ? "..."
                      : noround(earnTokPrice * (gap / 100), 3) +
                      " " +
                      payMethod.toUpperCase() +
                      " ~ $" +
                      minerPrice * (gap / 100)
                  }
                  disabled
                />
              </div>
              <div class="col-md-6">
                <label for="downline_rank" class="form-label">
                  Gap
                </label>
                <input
                  type="text"
                  class="form-control mb20"
                  id="downline_rank"
                  placeholder="..."
                  value={gap + "%"}
                  onChange={(e) => {
                    setGap(e.target.value);
                  }}
                  disabled
                />
              </div>
              <div class="col-md-6">
                <label for="your_rank" class="form-label">
                  Mining Credits Bonus
                </label>
                <input
                  type="text"
                  class="form-control mb20"
                  id="your_rank"
                  placeholder="1 CBK"
                  value={
                    minerPrice == "..." || gap == "..." || earnTokPrice == "..."
                      ? "..."
                      : 0.01 * minerPrice + " CBK"
                  }
                  disabled
                />
              </div>
              <div class="col-md-6">
                <label for="downline_rank" class="form-label">
                  Percentage
                </label>
                <input
                  type="text"
                  class="form-control mb20"
                  id="downline_rank"
                  placeholder="1%"
                  value="1%"
                  disabled
                />
              </div>
            </div>
          </div>
          <CalcTables
            your={yourRank}
            down={downRank}
            updateParentValue={updateValue}
          />
        </div>
        <div class="lightblue-bg secpadding brblr mb20">
          <p class="text1">
            Your earnings are calculated by deducting your downline rank from
            your rank. The net difference betweent your rank and downline rank
            is what you collect.
          </p>
        </div>
      </div>
    </>
  );
}
