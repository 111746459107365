import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import dataVal from "../../data/Abis.json";
import { Spinner } from "reactstrap";

let ttoken = [];

export default function CalcTables(props) {
  let navigate = useNavigate();

  const Web3 = require("web3");
  const web3 = new Web3(process.env.REACT_APP_RPC);

  const [rankDetails, setrankDetails] = useState({});
  const [rankloader, setrankloader] = useState(false);

  const getrankdetails = async (rank) => {
    const rankInstance = new web3.eth.Contract(
      dataVal.rankabi,
      process.env.REACT_APP_RANK_ADDR
    );
    await rankInstance.methods
      .fetchRank(rank)
      .call()
      .then((res) => {
        let perctconv = parseFloat(res[0]) / 10000;
        let prevrankdetails = {
          [rank]: {
            percent: parseFloat(perctconv),
            sales: web3.utils.fromWei(res[1], "ether"),
          },
        };
        let testobj = ttoken;
        testobj = Object.assign({}, testobj, prevrankdetails);
        ttoken = Object.assign({}, testobj, prevrankdetails);
        setrankDetails(testobj);
      });
  };

  useEffect(() => {
    let rankarray = new Array(17).fill();
    rankarray.map((e, i) => {
      i ? getrankdetails(i) : console.log("i not defined");
    });
  }, []);

  useEffect(() => {
    var keyCount = Object.keys(rankDetails).length;
    keyCount == 16 ? setrankloader(true) : setrankloader(false);
  }, [rankDetails]);

  useEffect(() => {
    if (
      rankloader &&
      props.your &&
      props.your !== "..." &&
      props.down &&
      props.down !== "..." &&
      props.your < 17 &&
      props.down < 17 &&
      props.your > 0 &&
      props.down > 0
    ) {
      props.updateParentValue(
        rankDetails[props.your].percent,
        rankDetails[props.down].percent
      );
    }
  }, [props.your, props.down]);

  return (
    <>
      {rankloader == false ? (
        <div style={{ padding: "50px", textAlign: "center" }}>
          <Spinner />
        </div>
      ) : (
        <div class="detailCard secpadding" style={{ borderRadius: "8px" }}>
          <div class="reftablesec">
            <div class="rankpertable table-responsive">
              <table class="table-bordered">
                <thead>
                  <tr>
                    <th class="tablebg1">Percentage</th>
                    <th class="tablebg2">Your rank</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(rankDetails).map((key, i) => {
                    return (
                      <tr>
                        <td
                          class={
                            parseInt(props.your) == i + 1
                              ? "tablebg1 active"
                              : "tablebg1"
                          }
                        >
                          {Object.values(rankDetails)[i].percent}%
                        </td>
                        <td
                          class={
                            parseInt(props.your) == i + 1
                              ? "tablebg2 active"
                              : "tablebg2"
                          }
                        >
                          {i + 1}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div class="downlinepertable table-responsive">
              <table class="table-bordered">
                <thead>
                  <tr>
                    <th class="tablebg3">Downline rank</th>
                    <th class="tablebg4">Percentage</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(rankDetails).map((key, i) => {
                    return (
                      <tr>
                        <td
                          class={
                            parseInt(props.down) == i + 1
                              ? "tablebg3 active"
                              : "tablebg3"
                          }
                        >
                          {i + 1}
                        </td>
                        <td
                          class={
                            parseInt(props.down) == i + 1
                              ? "tablebg4 active"
                              : "tablebg4"
                          }
                        >
                          {" "}
                          {Object.values(rankDetails)[i].percent}%
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
