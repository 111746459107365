import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import dataVal from "../../data/Abis.json";

export default function SelectPayCard(props) {
  let navigate = useNavigate();
  const Web3 = require("web3");
  const web3 = new Web3(process.env.REACT_APP_RPC);
  const [currentPrice, setCurrentPrice] = useState("...");
  const [minerTokPrice, setMinerTokPrice] = useState("...");
  const [stdEgBuy, setstdEgBuy] = useState("...");
  const [stdEgSell, setstdEgSell] = useState("...");

  useEffect(() => {
    if (!isNaN(props.price)) {

      getpriceinfo()
    };
  }, [props.price]);

  useEffect(() => {
    if (props.selected == props.currency)
      props.updateParentValue(minerTokPrice);
  }, [props.selected]);

  const getpriceinfo = async () => {

    const urlprice =
      "https://egold-marketdata.herokuapp.com/v1/summary/getTokenPrices/" +
      props.address;

    fetch(urlprice)
      .then(function (response) {
        return response.json();
      })
      .then(function (prices) {
        if ("price" in prices) {
          let pricep = prices.price;
          setCurrentPrice(noround(pricep, 2));
        } else {
          setCurrentPrice(roundzeroes(1, 2));
        }
      });

    if (props.convert == "true") {

      const routerInstance = new web3.eth.Contract(
        dataVal.pcrouterabi,
        process.env.REACT_APP_PANCAKE_ROUTER_ADDR
      );

      if (props.currency.includes("egold")) {
        console.log("here1st")
        if (parseInt(props.price) > 2000)
          var uprate =
            parseFloat(props.price) + parseFloat(props.price) * 0.002;
        else
          var uprate =
            parseFloat(props.price) + parseFloat(props.price) * 0.007;

        // await routerInstance.methods
        //   .getAmountsIn(web3.utils.toWei(uprate.toString(), "ether"), [
        //     props.address,
        //     process.env.REACT_APP_BUSD_ADDR,
        //   ])
        //   .call()
        //   .then((data) => {
        //     setMinerTokPrice(noround(web3.utils.fromWei(data[0], "ether"), 4));
        //   });

        // await routerInstance.methods
        //   .getAmountsIn(web3.utils.toWei(props.price.toString(), "ether"), [
        //     props.address,
        //     process.env.REACT_APP_BUSD_ADDR,
        //   ])
        //   .call()
        //   .then((res) => {
        //     setstdEgSell(noround(web3.utils.fromWei(res[0], "ether"), 3));
        //   });

        // await routerInstance.methods
        //   .getAmountsOut(web3.utils.toWei(props.price.toString(), "ether"), [
        //     process.env.REACT_APP_BUSD_ADDR,
        //     props.address,
        //   ])
        //   .call()
        //   .then((data) => {
        //     setstdEgBuy(noround(web3.utils.fromWei(data[1], "ether"), 3));
        //   });
        if (props.currency == "egoldv1") {
          console.log("egoldv111")
          await routerInstance.methods
            .getAmountsIn(web3.utils.toWei(uprate.toString(), "ether"), [
              props.address,
              process.env.REACT_APP_BUSD_ADDR,
            ])
            .call()
            .then((data) => {
              setMinerTokPrice(
                noround(web3.utils.fromWei(data[0], "ether"), 4)
              );
            });

          await routerInstance.methods
            .getAmountsIn(web3.utils.toWei(props.price.toString(), "ether"), [
              props.address,
              process.env.REACT_APP_BUSD_ADDR,
            ])
            .call()
            .then((res) => {
              setstdEgSell(noround(web3.utils.fromWei(res[0], "ether"), 3));
            });

          await routerInstance.methods
            .getAmountsOut(web3.utils.toWei(props.price.toString(), "ether"), [
              process.env.REACT_APP_BUSD_ADDR,
              props.address,
            ])
            .call()
            .then((data) => {
              setstdEgBuy(noround(web3.utils.fromWei(data[1], "ether"), 3));
            });
        } else if (props.currency == "egoldv2") {
          console.log("egoldv222")
          await routerInstance.methods
            .getAmountsIn(web3.utils.toWei(uprate.toString(), "ether"), [
              props.address,
              process.env.REACT_APP_USDT_ADDR,
            ])
            .call()
            .then((data) => {
              setMinerTokPrice(
                noround(web3.utils.fromWei(data[0], "ether"), 4)
              );
            });

          await routerInstance.methods
            .getAmountsIn(web3.utils.toWei(props.price.toString(), "ether"), [
              props.address,
              process.env.REACT_APP_USDT_ADDR,
            ])
            .call()
            .then((res) => {
              setstdEgSell(noround(web3.utils.fromWei(res[0], "ether"), 3));
            });

          await routerInstance.methods
            .getAmountsOut(web3.utils.toWei(props.price.toString(), "ether"), [
              process.env.REACT_APP_USDT_ADDR,
              props.address,
            ])
            .call()
            .then((data) => {
              setstdEgBuy(noround(web3.utils.fromWei(data[1], "ether"), 3));
            });
        }
      } else {
        var uprate = parseFloat(props.price) + parseFloat(props.price) * 0.01;
        await routerInstance.methods
          .getAmountsOut(web3.utils.toWei(uprate.toString(), "ether"), [
            process.env.REACT_APP_BUSD_ADDR,
            props.address,
          ])
          .call()
          .then((data) => {
            setMinerTokPrice(noround(web3.utils.fromWei(data[1], "ether"), 4));
          });
      }
    } else {
      setMinerTokPrice(roundzeroes(props.price, 2));
    }
  };

  function noround(number, decimalDigits) {
    const powerOfTen = Math.pow(10, decimalDigits);
    const formattedNumber = Math.floor(number * powerOfTen) / powerOfTen;
    if (Number.isInteger(formattedNumber)) {
      return roundzeroes(formattedNumber, 2);
    } else return formattedNumber;
  }

  //to round decimal points with zeroes
  function roundzeroes(val, x) {
    var float = parseFloat(val).toFixed(18);
    var num = float.toString();
    var n = num.slice(0, num.indexOf(".") + (x + 1));
    return n;
  }

  return (
    <>
      <div
        class={
          props.currency.includes("egold") ? "selectcurrow focused" : "selectcurrow"
        }
      >
        <div
          class={
            props.selected == props.currency
              ? "selectcurrowcontent  darksec-bg"
              : "selectcurrowcontent"
          }
        >
          <div class="selectcurimgside">
            <div class="curicon">
              <img
                src={require("../../images/currency/" +
                  props.currency +
                  ".svg")}
                class="img-fluid"
                style={{ width: "35px" }} //remove after cbk logo svg fixed
              />
            </div>
            <div
              class="curicondel"
              style={{ fontSize: "14px", fontWeight: "600" }}
            >
              <p>
                {props.currency == "egoldv1" ? "EGOLD (V1)" : props.currency == "egoldv2" ? "EGOLD (V2)" : props.currency.toUpperCase()}{" "}
                <span
                  class="badge rounded-pill text-bg-secondary selectcurpricep"
                  style={{ backgroundColor: "#E0E7EA", color: "#4F6B75" }}
                >
                  BEP20
                </span>
              </p>
              <p class="selectcurpricep">
                ${currentPrice}
                {/* <span class="greentext">+0.02%</span> */}
              </p>
            </div>
          </div>
          <div class="selectdetailside">
            <p class="selectcuramt">{minerTokPrice}</p>
            <p class="selectcurpricep text-end">
              ${isNaN(props.price) ? "..." : roundzeroes(props.price, 2)}
            </p>
          </div>
        </div>
        {props.currency.includes("egold") && (
          <div class="selectcurfocused">
            <p class="selectcurpricep greentext fw500">
              Buy : {props.price} BUSD ~ {stdEgBuy} Egold
            </p>
            <p class="selectcurpricep redtext fw500">
              Sell: {props.price} BUSD ~ {stdEgSell} Egold
            </p>
          </div>
        )}
      </div>
    </>
  );
}
