import React, { useEffect, useState } from 'react'
import { IoArrowBackCircleOutline, } from "react-icons/io5";
import BackButton from '../Buttons/BackButton/BackButton';
import "./login.css"
import { FaRegCopy } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import QRCode from "react-qr-code";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Input,
    InputGroup,
    InputGroupText,
} from "reactstrap";
import LoaderCard from "../LoaderCard/LoaderCard";
import ConfirmCard from "../LoaderCard/ConfirmCard";
import ErrorCard from "../LoaderCard/ErrorCard";
import { getqrcode, enabletwofactor, invokeservice } from "../../api/apiService";
import { FaRegPaste } from "react-icons/fa6";
export default function ActivateFA() {
    const status = localStorage.getItem("twoFaStatus");
    let history = useNavigate();

    const [showQR, setShowQR] = useState(false);
    const [setupKey, setSetupKey] = useState("");
    const [setupQR, setsetupQR] = useState("");
    const [copied, setCopied] = useState(false);
    const [OTP, setOTP] = useState("");
    const [processState, setprocessState] = useState({
        state: "...",
        data: null,
    });

    useEffect(() => {
        if (status == true) {
            history("/buy");
        } else enableAuth();
    }, []);

    async function enableAuth() {
        setShowQR(false);
        const token = localStorage.getItem("accessToken");
        var headers = {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + String(token),
            },
        };
        getqrcode(headers).then((json) => {
            setSetupKey(json.data.setupKey);
            setsetupQR(json.data.qrURI);
            setShowQR(true);
        });
    }

    async function enabletwofactorauth() {
        if (OTP.length === 6) {
            setprocessState({ state: "processing", data: null });
            const token = localStorage.getItem("accessToken");
            const data = { otp: Number(OTP) };
            const createwalletdata = {
                job: {
                    type: "Createwallet",
                    params: {
                        user: localStorage.getItem("email"),
                        useragent: navigator.userAgent,
                    },
                },
            };

            console.log("cwdata-", createwalletdata);

            var headers = {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + String(token),
                },
            };

            enabletwofactor(data, headers).then((json) => {
                if (json.data.code == 201) {
                    localStorage.setItem("twoFaStatus", true);
                    invokeservice(createwalletdata, headers).then((json) => {
                        if (json.status == 201) {
                            setprocessState({ state: "done", data: "Successfully Enabled 2FA" });
                            localStorage.setItem("acct", json.data.wallet_address);
                            history("/buy");
                        } else {
                            setprocessState({
                                state: "error",
                                data: json.data.message
                            });
                        }
                    });
                } else {
                    setprocessState({
                        state: "error",
                        data: json.data.message
                    });
                }
            });
        } else {
            setprocessState({
                state: "error",
                data: "Incorrect 2FA"
            });
        }

    }
    useEffect(() => {
        if (
            localStorage.getItem("isAuthenticated") === "true" &&
            localStorage.getItem("twoFaStatus") === "true"
        ) {
            history("/buy");
        }
    }, []);


    return (
        <main style={{ marginTop: 150 }}>
            <div class="mainsection">
                <div class="tabsec" style={{ padding: 30, background: "#F5F5F5", borderRadius: 8 }}>
                    <BackButton Title="Activate 2FA" ReRoute="" />
                    <div className='autosteps'>

                        <span className='steptext' >
                            Secure your account well, as losing your mobile means losing any chance of account recovery.
                        </span>
                        <div style={{ display: "grid" }}>
                            <span className='steptext' >
                                1. Install an Authenticator app on your mobile device
                            </span>
                            <span className='steptext' >
                                2. Scan the QR code below with your app. If you can't scan the QR code enter this code :
                            </span>
                        </div>
                        <div style={{ marginTop: 15, display: "grid", justifyContent: "center", alignItems: "center" }}>
                            <span style={{ color: "#253237" }}>
                                {setupKey}
                            </span>
                            <span style={{ fontSize: 12, textAlign: "center", marginTop: 5, color: "#253237", cursor: "pointer" }} onClick={async () => {
                                await navigator.clipboard.writeText(setupKey);
                                setCopied(true);
                                setTimeout(function () {
                                    setCopied(false);
                                }, 2000);
                            }}>
                                {copied ? "Copied" : "Copy"} <FaRegCopy color='#253237' size={22} />
                            </span>
                        </div>
                        <div style={{ padding: 10, display: "flex", justifyContent: "center", alignItems: "center", marginTop: 15 }}>
                            <div style={{ background: "#F1F1F1", padding: 25, display: "flex", justifyContent: "center", alignItems: "center", marginTop: 15, borderRadius: 8 }}>
                                {showQR ? (<QRCode value={setupQR} size={256} />) : null}
                            </div>
                        </div>
                        <div style={{ marginTop: 30, padding: 10 }}>
                            <label for="downline_rank" class="form-label" style={{ color: "#2D3748", fontSize: 15 }}>
                                2FA
                            </label>
                            <InputGroup>
                                <Input
                                    className="withdrawinput"
                                    type="text"
                                    name="amount"
                                    value={OTP}
                                    placeholder='Enter 2FA code '
                                    id="pw"
                                    style={{
                                        backgroundColor: "#fff",
                                        border: " 0.489247px solid #dee2e6",
                                        borderRight: "0px",
                                        color: "#000",
                                        fontSize: "13px",
                                        padding: "13px 12px",
                                        borderRadius: "8px 0px 0px 8px",
                                    }}
                                    onChange={(otp) => {
                                        setOTP(otp.target.value);
                                    }}
                                />
                                <InputGroupText
                                    className="inputicon"
                                >

                                    <FaRegPaste color='black' size={22} />

                                </InputGroupText>
                            </InputGroup>
                        </div>
                        <div style={{ marginTop: 30, padding: 10 }}>
                            <button className='btnlogin' onClick={() => {
                                enabletwofactorauth();
                            }}>
                                Enable 2FA
                            </button>
                        </div>
                        {processState.state == "..." ? (
                            ""
                        ) : processState.state == "processing" ? (
                            <LoaderCard />
                        ) : processState.state == "done" ? (
                            <div className="convertsionbox" style={{ marginTop: 20 }}>
                                <ConfirmCard tx={processState.data} />
                            </div>
                        ) : (
                            <div className="convertsionbox" style={{ marginTop: 20 }}>
                                <ErrorCard err={processState.data} />
                            </div>
                        )}
                    </div>
                </div>


            </div>
        </main>
    )
}
