import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Spinner } from "reactstrap";
import dataVal from "../../data/Abis.json";
import LoaderCard from "../LoaderCard/LoaderCard";
import ConfirmCard from "../LoaderCard/ConfirmCard";
import ErrorCard from "../LoaderCard/ErrorCard";
import { invokeservice } from "../../api/apiService";
import { FaRegPaste } from "react-icons/fa6";
import { Input, InputGroup, InputGroupText } from "reactstrap";
const Web3 = require("web3");
const web3 = new Web3(process.env.REACT_APP_RPC);

export default function MinerCard(props) {
    let navigate = useNavigate();
    const [isOpen, setOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(1);
    const [amnt, setAmnt] = useState(null);
    const [tokenBalance, setTokenBalance] = useState("....");
    const [processState, setprocessState] = useState({
        state: "...",
        data: null,
    });
    const [OTP, setOTP] = useState("");
    const [tokenv2Balance, setTokenv2Balance] = useState("....");
    const [errorMSG, seterrorMSG] = useState({ status: false, message: "" });
    const [loadbtn, setloadbtn] = useState(false);
    const [convBtn, setconvBtn] = useState("v2");

    const CONTRACT_ADDRESS = dataVal.convertercontract;
    const CONTRACT_ABI = dataVal.converterabi;

    const TOKEN_ABI = dataVal.tokenabi;
    const V1_TOKEN = process.env.REACT_APP_EGOLD_ADDR;
    const V2_TOKEN = process.env.REACT_APP_EGOLDV2_ADDR;

    const tokencontractInstancev1 = new web3.eth.Contract(TOKEN_ABI, V1_TOKEN);
    const tokencontractInstancev2 = new web3.eth.Contract(TOKEN_ABI, V2_TOKEN);

    useEffect(() => {
        getSummary();
    }, []);

    useEffect(() => {
        getSummary();
    }, [selectedItem]);

    const getSummary = async () => {
        var wAddress = localStorage.getItem("acct");
        if (wAddress) {
            await tokencontractInstancev1.methods
                .balanceOf(localStorage.getItem("acct"))
                .call()
                .then((value) =>
                    setTokenBalance(noround(web3.utils.fromWei(value, "ether"), 3))
                )
                .catch((error) => console.error(error));

            await tokencontractInstancev2.methods
                .balanceOf(localStorage.getItem("acct"))
                .call()
                .then((value) =>
                    setTokenv2Balance(noround(web3.utils.fromWei(value, "ether"), 3))
                )
                .catch((error) => console.error(error));

        }
    };

    function handleConvert() {
        if (amnt != null) {
            if (amnt <= 0.0) {
                setprocessState({ state: "error", data: "Invalid amount" });
            } else {
                seterrorMSG({ status: false, message: "" })
                try {
                    const token = localStorage.getItem("accessToken");

                    setprocessState({ state: "processing", data: null });
                    var headers = {
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + String(token),
                        },
                    };

                    if (selectedItem == "2") {
                        if (amnt <= tokenv2Balance) {
                            let transferData = {
                                job: {
                                    type: "ConvertV2toV1",
                                    params: {
                                        useragent: "mobile",
                                        user: localStorage.getItem("email"),
                                        amount: amnt,
                                        totp: localStorage.getItem("isoptionaltfa") == "true" ? OTP : "",
                                    }
                                }
                            }
                            invokeservice(transferData, headers).then((json) => {
                                if (json.data.status != "failed") {
                                    setprocessState({ state: "done", data: "Conversion done successfully" });
                                } else {
                                    setprocessState({ state: "error", data: json.data.message });
                                }
                            });
                        } else {
                            setprocessState({ state: "error", data: "Insufficient balance" });
                        }

                    } else if (selectedItem == "1") {
                        if (amnt <= tokenBalance) {

                            let transferData = {
                                job: {
                                    type: "ConvertV1toV2",
                                    params: {
                                        useragent: "mobile",
                                        user: localStorage.getItem("email"),
                                        amount: amnt,
                                        totp: localStorage.getItem("isoptionaltfa") == "true" ? OTP : "",
                                    }
                                }
                            }
                            invokeservice(transferData, headers).then((json) => {
                                if (json.data.status != "failed") {
                                    setprocessState({ state: "done", data: "Conversion done successfully" });
                                } else {
                                    setprocessState({ state: "error", data: json.data.message });
                                }
                            });
                        } else {
                            setprocessState({ state: "error", data: "Insufficient balance" });
                        }

                    }


                } catch (e) {
                    setprocessState({ state: "error", data: JSON.stringify(e.message) });
                    console.log(e);
                }
            }
        } else {
            setprocessState({ state: "error", data: "Invalid amount" });
            seterrorMSG({ status: true, message: "Invalid amount" })
        }
    }

    const toggleDropdown = () => setOpen(!isOpen);

    const handleItemClick = (id) => {
        console.log("Idddd", id)
        if (id) {
            if (id == 1) {
                setconvBtn("v2")
            } else {
                setconvBtn("v1")
            }
            setSelectedItem(id);
            getSummary();
            setOpen(!isOpen);
        }
    };

    function checkerror(err) {
        if (
            err.message ==
            "Please pass numbers as strings or BN objects to avoid precision errors." ||
            err.message.includes(
                "while converting number to string, invalid number value "
            )
        ) {
            setprocessState({
                state: "error",
                data: "Please provide a valid input",
            });
        } else if (JSON.stringify(err.message).includes("transaction underpriced"))
            setprocessState({
                state: "error",
                data: "Transaction was underpriced. Please try increasing the gas price",
            });
        else
            setprocessState({
                state: "error",
                data: JSON.stringify(err.message),
            });
    }

    function numFormatter(num) {
        if (num > 999 && num < 1000000) {
            return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
        } else if (num > 1000000) {
            return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
        } else if (num < 1000) {
            var x = noround(num, 2);
            return x; // if value < 1000, nothing to do
        }
    }

    function noround(number, decimalDigits) {
        const powerOfTen = Math.pow(10, decimalDigits);
        const formattedNumber = Math.floor(number * powerOfTen) / powerOfTen;
        if (Number.isInteger(formattedNumber)) {
            return roundzeroes(formattedNumber, 2);
        } else return formattedNumber;
    }

    //to round decimal points with zeroes
    function roundzeroes(val, x) {
        var float = parseFloat(val).toFixed(18);
        var num = float.toString();
        var n = num.slice(0, num.indexOf(".") + (x + 1));
        return n;
    }
    return (
        <>
            <div class="walletamtsec mb20" >
                <div class="lightgrey-bg secpadding brrr">
                    <div class="row" style={{ marginBottom: "14px" }}>
                        <div class="col-12 d-flex align-items-center justify-content-center">
                            <p class="referheading">
                                EGOLD (V1){" "}
                                <span className="midpart">
                                    {"<"}={">"}
                                </span>{" "}
                                EGOLD (V2){" "}
                            </p>
                        </div>
                    </div>
                    <p
                        class="fs12"
                        style={{
                            padding: "12px",
                            paddingTop: "0px",
                            paddingBottom: "6px",
                            color: "#959494",
                        }}
                    >
                        V1 Balance: {tokenBalance}{" "}
                        <span
                            style={{ float: "right" }}
                        >
                            V2 Balance: {tokenv2Balance}
                        </span>
                    </p>
                    <div class="detailCard mb20">
                        <div class="fromreactangle pr mb20">
                            <div class="p15">
                                <p class="fs12 mb10">Qty</p>
                                <p class="fromreactp">
                                    {" "}
                                    <input
                                        className="swapinput"
                                        placeholder="0.00"
                                        value={amnt}
                                        onChange={(e) => setAmnt(e.target.value)}
                                    />
                                    <a class="selecttokendrop">
                                        <span class="float-right fromreactspan dropwrapper">
                                            <div class="dropdown">
                                                <div class="dropdown-header" onClick={toggleDropdown}>
                                                    {selectedItem == "1" ? (
                                                        <span>V1 -{">"} V2</span>
                                                    ) : (
                                                        <span>V2 -{">"} V1</span>
                                                    )}
                                                    <svg
                                                        height="15px"
                                                        viewBox="0 0 24 24"
                                                        width="12px"
                                                        fill="#000000"
                                                    >
                                                        <path d="M0 0h24v24H0z" fill="none"></path>
                                                        <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
                                                    </svg>
                                                </div>
                                                <div className={`dropdown-body ${isOpen && "open"}`}>
                                                    <div
                                                        class="dropdown-item"
                                                        onClick={(e) => handleItemClick(e.target.id)}
                                                        id="1"
                                                    >
                                                        <span
                                                            class="busdicon"
                                                            alt=""
                                                            id="1"
                                                            onClick={(e) => handleItemClick(e.target.id)}
                                                        ></span>
                                                        V1 -{">"} V2
                                                        <span
                                                            className={`dropdown-item-dot ${selectedItem == 1 && "selected"
                                                                }`}
                                                        >
                                                            •{" "}
                                                        </span>
                                                    </div>
                                                    <div
                                                        class="dropdown-item"
                                                        onClick={(e) => handleItemClick(e.target.id)}
                                                        id="2"
                                                    >
                                                        <span
                                                            class="bnbicon"
                                                            alt=""
                                                            id="2"
                                                            onClick={(e) => handleItemClick(e.target.id)}
                                                        ></span>
                                                        V2 -{">"} V1
                                                        <span
                                                            className={`dropdown-item-dot ${selectedItem == 2 && "selected"
                                                                }`}
                                                        >
                                                            •{" "}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <span className="egoldicon"></span> */}
                                        </span>
                                    </a>
                                    <span
                                        class="float-right yellowtext fs14"
                                        style={{
                                            cursor: "pointer",
                                        }}
                                        onClick={() => setAmnt(tokenBalance)}
                                    >
                                        Use Max
                                    </span>
                                </p>

                                {/* Balance: {tokenBalance}{" "} */}


                            </div>
                        </div>
                    </div>
                    {localStorage.getItem("isoptionaltfa") == "true" ? (
                        <div style={{ marginTop: 20, marginBottom: 30 }}>
                            <div style={{ marginTop: 10 }}>
                                <label for="receiver_address" class="form-label">
                                    Please enter 2FA Code :
                                </label>
                                <InputGroup>
                                    <Input
                                        name="otp"
                                        type={"text"}
                                        id="otp"
                                        value={OTP}
                                        onChange={(otp) => {
                                            setOTP(otp.target.value);
                                        }}
                                        style={{
                                            borderTop: "0.489247px solid rgb(222, 226, 230)",
                                        }}
                                        className="passwordbox"
                                    />

                                    <InputGroupText
                                        className={"inputicon"}
                                        onClick={async () => {
                                            setOTP(await navigator.clipboard.readText());
                                        }}
                                    >
                                        <FaRegPaste color="black" size={22} />
                                    </InputGroupText>
                                </InputGroup>
                            </div>
                        </div>
                    ) : null}

                    <div style={{ display: "flex", gap: "4%" }}>
                        <a
                            className={amnt == null ? `btn-color-primary disabled` : `btn-color-primary`}
                            style={{ cursor: "pointer", width: "100%" }}
                            onClick={() => {
                                amnt == null ? console.log("--") :
                                    handleConvert()
                            }}
                        >
                            {loadbtn ? <Spinner size="sm" /> : convBtn == "v2" ? "Convert to V2" : "Convert to V1"}
                        </a>
                    </div>

                    {processState.state == "..." ? (
                        ""
                    ) : processState.state == "processing" ? (
                        <>
                            <br />
                            <LoaderCard />
                        </>
                    ) : processState.state == "done" ? (
                        <>
                            <br />
                            <ConfirmCard tx={processState.data} />
                        </>
                    ) : (
                        <>
                            <br />
                            <ErrorCard err={processState.data} />
                        </>
                    )}
                </div>
                {/* <div class="rcard-bg secpadding brblr">
          <p class="text2">
            Lifetime sales reflect the total amount of sales made by your
            account in all currencies, converted to a dollar value. 'Collected'
            indicates the commission you have already claimed, while 'Pending'
            reflects outstanding claims. Click on 'Collect' to claim your
            earnings.
          </p>
        </div> */}
            </div>
        </>
    );
}
