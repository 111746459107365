import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import dataVal from "../../data/Abis.json";
import { Spinner } from "reactstrap";
const Web3 = require("web3");
const web3 = new Web3(process.env.REACT_APP_RPC);

export default function MinerCard(props) {
  let navigate = useNavigate();
  const [currentActive, setCurrentActive] = useState("...");
  const [currentblockNumber, setcurrentblockNumber] = useState("...");

  const apiUrl =
    "https://egold-treasury.tagdev.info/v1/summary/fsMinersOf/" + localStorage.getItem("acct");

  const fetchData = async () => {
    try {
      if (localStorage.getItem("acct")) {
        const cbn = await web3.eth.getBlockNumber();
        setcurrentblockNumber(cbn);
        console.log("fetching");
        const response = await fetch(apiUrl);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const jsonData = await response.json();
        console.log("FS", jsonData);
        setCurrentActive(jsonData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  async function getTimeUntilBlock(blockNumber) {
    try {
      const latestBlock = await web3.eth.getBlockNumber();

      if (blockNumber <= latestBlock) {
        return "0d:0h:0m";
      }

      const blockTime = 3;

      const remainingBlocks = blockNumber - latestBlock;
      const remainingTimeInSeconds = remainingBlocks * blockTime;

      const days = Math.floor(remainingTimeInSeconds / (3600 * 24));
      const hours = Math.floor((remainingTimeInSeconds % (3600 * 24)) / 3600);
      const minutes = Math.floor((remainingTimeInSeconds % 3600) / 60);

      const formattedTime = `${days}d:${hours}h:${minutes}m`;

      return `${formattedTime}`;
    } catch (error) {
      return `Error: ${error.message}`;
    }
  }

  function BlockTimer({ targetBlockNumber }) {
    const [remainingTime, setRemainingTime] = useState("...");

    useEffect(() => {
      const init = async () => {
        const timeRemaining = await getTimeUntilBlock(targetBlockNumber);
        setRemainingTime(timeRemaining);
      }
      init();
      const interval = setInterval(async () => {
        const timeRemaining = await getTimeUntilBlock(targetBlockNumber);
        setRemainingTime(timeRemaining);

        if (timeRemaining === "0h:0m:0s") {
          clearInterval(interval);
        }
      }, 30000);

      return () => clearInterval(interval);
    }, [targetBlockNumber]);

    return <p className="qscardtile">Time left : {remainingTime}</p>;
  }


  function numFormatter(num) {
    if (num > 999 && num < 1000000) {
      return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
      return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
    } else if (num < 1000) {
      var x = noround(num, 2);
      return x; // if value < 1000, nothing to do
    }
  }
  function noround(number, decimalDigits) {
    const powerOfTen = Math.pow(10, decimalDigits);
    const formattedNumber = Math.floor(number * powerOfTen) / powerOfTen;
    if (Number.isInteger(formattedNumber)) {
      return roundzeroes(formattedNumber, 2);
    } else return formattedNumber;
  }
  //to round decimal points with zeroes
  function roundzeroes(val, x) {
    var float = parseFloat(val).toFixed(18);
    var num = float.toString();
    var n = num.slice(0, num.indexOf(".") + (x + 1));
    return n;
  }
  return (
    <>
      <div class="walletamtsec mb20">
        <div class="bg-dbg secpadding brtlr">
          <div class="row">
            {currentActive == "..." || currentActive.FS.length == 0 ? (
              <div class="col-12 d-flex align-items-center">
                <p class="dbgreferheading">Quick Start Bonus</p>
              </div>
            ) : (
              <>
                <div class="col-6 d-flex align-items-center">
                  <p class="dbgreferheading">Quick Start Bonus</p>
                </div>
                <div class="col-6 d-flex align-items-center" style={{ justifyContent: "end" }}>
                  <p class="dbgreferheading"> Activated : {currentActive.FS.length}</p>
                </div>
              </>
            )}
          </div>
          <hr class="mobrefhr" style={{ backgroundColor: "#E2E8F0" }} />
          <div class="d-flex justify-content-between">
            <h5 className="quicktext">
              Join the Quick Start Bonus program and gain free mining credits
              upon completing tasks.
            </h5>
          </div>
        </div>
        <div class="dbg-card secpadding brblr">
          <div class="selectcurbtnsec">
            <a
              class="btn-outline-color-secondary"
              style={{ cursor: "pointer", border: "1px solid #4F6B75" }}
              onClick={() => {
                navigate("/quickrules");
              }}  >
              Rules
            </a>
            {/* {currentActive == "..." ? (
              <a class="btn-color-primary">
                <Spinner />
              </a>
            ) : currentActive.status ? (
              <a
                class="btn-color-primary"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/quicktrack/" + currentActive.id);
                }}
              >
                Track
              </a>
            ) : ( */}
            {/* <a
                class="btn-color-primary"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/quickstart");
                }}
              >
                Start / Track
              </a> */}
            {/* )} */}
          </div>
        </div>
      </div>
    </>
  );
}
