import React, { useEffect, useState } from "react";
import { ResponsiveBar } from "@nivo/bar";
import { Spinner } from "reactstrap";

const ProductionCycles = () => {
  const [data, setData] = useState([]);
  const [showAllRows, setShowAllRows] = useState(false);

  const handleShowAll = () => {
    setShowAllRows(true);
  };
  const handleCloseAll = () => {
    setShowAllRows(false);
  };

  useEffect(() => {
    setData([
      {
        year: "1-4",
        tokens: 10000000,
        color: "#4F6B75 ",
      },
      {
        year: "5-8",
        tokens: 5000000,
        color: "#4F6B75 ",
      },
      {
        year: "9-12",
        tokens: 2500000,
        color: "#4F6B75 ",
      },
      {
        year: "13-16",
        tokens: 1250000,
        color: "#4F6B75 ",
      },
      {
        year: "17-20",
        tokens: 625000,
        color: "#4F6B75 ",
      },
      {
        year: "21-24",
        tokens: 312500,
        color: "#4F6B75 ",
      },
      {
        year: "25-28",
        tokens: 156250,
        color: "#4F6B75 ",
      },
      {
        year: "29-40",
        tokens: 136718.25,
        color: "#4F6B75 ",
      },
      {
        year: "41-80",
        tokens: 19531.26,
        color: "#4F6B75 ",
      },
    ]);
  }, []);

  //to not round values while showing balance
  function noround(val, x) {
    if (!val) return 0;
    var float = parseFloat(val).toFixed(18);
    var num = float.toString();
    var n = num.slice(0, num.indexOf(".") + (x + 1));
    return n;
  }
  const chartTheme = {
    axis: {
      ticks: {
        text: {
          fontSize: 8, // Adjust the font size as per your requirement
        },
      },
    },
  };

  return (
    <>
      {data.length == 0 ? (
        <div style={{ padding: "20px", textAlign: "center" }}>
          <Spinner />
        </div>
      ) : (
        <div className="chartbx">
          <div className="chbxhdiv mb20">
            <ResponsiveBar
              data={data}
              theme={window.innerWidth > 500 ? "" : chartTheme}
              keys={["tokens"]}
              indexBy="year"
              margin={
                window.innerWidth > 2500
                  ? { top: 50, right: 40, bottom: 50, left: 90 }
                  : window.innerWidth > 2000
                  ? { top: 50, right: 50, bottom: 50, left: 90 }
                  : window.innerWidth > 1200
                  ? { top: 50, right: 30, bottom: 50, left: 90 }
                  : window.innerWidth > 500
                  ? { top: 50, right: 20, bottom: 50, left: 90 }
                  : { top: 30, right: 10, bottom: 52, left: 50 }
              }
              padding={0.3}
              valueScale={{ type: "linear" }}
              indexScale={{ type: "band", round: true }}
              colors={(slice) => slice.data.color}
              borderColor={{
                from: "color",
                modifiers: [["darker", 1.6]],
              }}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Years",
                legendPosition: "middle",
                legendOffset: 39,
              }}
              axisLeft={{
                tickSize: 1,
                tickPadding: 0,
                tickRotation: 0,
                legend: "Tokens Produced",
                legendPosition: "middle",
                legendOffset: window.innerWidth > 500 ? -65 : -40,
              }}
              enableLabel={window.innerWidth > 500 ? true : false}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={{
                from: "color",
                modifiers: [["darker", 1.6]],
              }}
              tooltip={({ id, value, indexValue, color }) => (
                <div
                  style={{
                    padding: 12,
                    color,
                    background: "#222222",
                    textAlign: "center",
                  }}
                >
                  <span>Year {indexValue} </span>
                  <br />
                  Produces : {value}
                </div>
              )}
              role="application"
            />
          </div>
          <br className="mobnone" />
          <table class="table table-bordered text-center">
            <thead>
              <tr class="darktrbg text-white">
                <th>From year</th>
                <th>Tokens</th>
                <th>Per day</th>
              </tr>
            </thead>
            <tbody>
              <tr class="whitebg">
                <td>1-4</td>
                <td>10,000,000</td>
                <td>6849.315068</td>
              </tr>
              <tr class="lightdarkbg">
                <td>5-8</td>
                <td>5,000,000</td>
                <td>3424.657534</td>
              </tr>
              <tr class="whitebg">
                <td>9-12</td>
                <td>2,500,000</td>
                <td>1712.328767</td>
              </tr>
              <tr class="lightdarkbg">
                <td>13-16</td>
                <td>1,250,000</td>
                <td>856.1643836</td>
              </tr>
              <tr class="whitebg">
                <td>17-20</td>
                <td>625,000</td>
                <td>428.0821918</td>
              </tr>
              <tr class="lightdarkbg">
                <td>21-24</td>
                <td>312,500</td>
                <td>214.0410959</td>
              </tr>
              <tr class="whitebg">
                <td>25-28</td>
                <td>156,250</td>
                <td>107.0205479</td>
              </tr>
              <tr class="lightdarkbg">
                <td>29 - 32</td>
                <td>78,125</td>
                <td>53.51027397</td>
              </tr>
              {showAllRows && (
                <>
                  <tr class="whitebg">
                    <td>33 - 36</td>
                    <td>39,062</td>
                    <td>26.75513698</td>
                  </tr>
                  <tr class="lightdarkbg">
                    <td>37 - 40</td>
                    <td>19,531</td>
                    <td>13.37756849</td>
                  </tr>
                  <tr class="whitebg">
                    <td>41 - 44</td>
                    <td>9,766</td>
                    <td>6.688784244</td>
                  </tr>
                  <tr class="lightdarkbg">
                    <td>45 - 48</td>
                    <td>4,882</td>
                    <td>3.344392122</td>
                  </tr>
                  <tr class="whitebg">
                    <td>41 - 44</td>
                    <td>9,766</td>
                    <td>6.688784244</td>
                  </tr>
                  <tr class="lightdarkbg">
                    <td>45 - 48</td>
                    <td>4,882</td>
                    <td>3.344392122</td>
                  </tr>
                  <tr class="whitebg">
                    <td>49 - 52</td>
                    <td>2,441</td>
                    <td>1.672196061</td>
                  </tr>
                  <tr class="lightdarkbg">
                    <td>53 - 56</td>
                    <td>1,221</td>
                    <td>0.8360980306</td>
                  </tr>
                  <tr class="whitebg">
                    <td>57 - 60</td>
                    <td>610.35</td>
                    <td>0.418049015</td>
                  </tr>
                  <tr class="lightdarkbg">
                    <td>61 - 64</td>
                    <td>305.18</td>
                    <td>0.209024508</td>
                  </tr>
                  <tr class="whitebg">
                    <td>65 - 68</td>
                    <td>152.59</td>
                    <td>0.104512254</td>
                  </tr>
                  <tr class="lightdarkbg">
                    <td>69 - 72</td>
                    <td>76.29</td>
                    <td>0.052256127</td>
                  </tr>
                  <tr class="whitebg">
                    <td>73 - 76</td>
                    <td>38.15</td>
                    <td>0.026128063</td>
                  </tr>
                  <tr class="lightdarkbg">
                    <td>77 - 80</td>
                    <td>38.15</td>
                    <td>0.026128063</td>
                  </tr>
                </>
              )}
              {showAllRows ? (
                <tr
                  class="darktrbg text-center"
                  onClick={handleCloseAll}
                  style={{ cursor: "pointer" }}
                >
                  <td colspan="3">
                    <a class="text-white">Hide</a>
                  </td>
                </tr>
              ) : (
                <tr
                  class="darktrbg text-center"
                  onClick={handleShowAll}
                  style={{ cursor: "pointer" }}
                >
                  <td colspan="3">
                    <a class="text-white">View all</a>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default ProductionCycles;
