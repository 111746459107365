import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import dataVal from "../../data/Abis.json";
const Web3 = require("web3");
const web3 = new Web3(process.env.REACT_APP_RPC);

export default function MinerCard(props) {
  let navigate = useNavigate();
  const [level, setLevel] = useState("...");
  const [rankper, setRankPer] = useState("...");
  const [key, setkey] = useState(1);
  const CONTRACT_ADDRESS = process.env.REACT_APP_INDENTITY_ADDR;
  const CONTRACT_ABI = dataVal.identityabi;
  const contractInstance = new web3.eth.Contract(
    CONTRACT_ABI,
    CONTRACT_ADDRESS
  );

  const rankInstance = new web3.eth.Contract(
    dataVal.rankabi,
    process.env.REACT_APP_RANK_ADDR
  );

  async function checkRef() {
    var wAddress = localStorage.getItem("acct");
    if (wAddress) {
      await contractInstance.methods
        .fetchUser(localStorage.getItem("acct"))
        .call()
        .then(async (value) => {
          localStorage.setItem("lev", value.rank);
          setLevel(value.rank);
          setkey(key + 1);
          await rankInstance.methods
            .fetchRank(value.rank)
            .call()
            .then((res) => {
              setRankPer(parseFloat(res[0]) / 10000);
            });
        })
        .catch((error) => console.error(error));
    }
    return 1;
  }

  useEffect(() => {
    checkRef();
  }, []);

  return (
    <>
      <div class="walletamtsec mb20">
        <div class="refercardbg secpadding brtlr">
          <div class="row">
            <div class="col-6">
              <p class="referinvitep inviprefer">
                Current Rank:{" "}
                <span class="fw500" style={{ marginLeft: "5px" }}>
                  {level == "..." ? "..." : Number(level)}{" "}
                </span>
              </p>
              <p class="referinvitep inviprefer">
                Current Commission:{" "}
                <span class="fw500" style={{ marginLeft: "5px" }}>
                  {rankper}%
                </span>
              </p>
            </div>
            {props.refervalid && (
              <div class="col-6">
                <a
                  class="btn-bg-yellow complanbtn"
                  onClick={() => {
                    navigate("/compensationplan");
                  }}
                >
                  {" "}
                  Compensation Plan{" "}
                </a>
              </div>
            )}
          </div>
        </div>
        <div class="rcard-bg secpadding brblr">
          <p class="text2">
            Users earn commissions based on their rank and the associated
            percentage. As you sell more and achieve targets, both your rank and
            commission will increase. For indirect sales, you earn commissions
            based on the difference between your rank and your downline's rank.
            Please refer to the referral calculator below.
          </p>
        </div>
      </div>
    </>
  );
}
