import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ModalBody, Modal, ModalHeader, Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";

import { AiOutlineInfoCircle } from "react-icons/ai";
import { Spinner } from "reactstrap";
import dataVal from "../../../data/Abis.json";
import LoaderCard from "../../LoaderCard/LoaderCard";
import ConfirmCard from "../../LoaderCard/ConfirmBuy";
import ErrorCard from "../../LoaderCard/ErrorCard";
import { FaRegPaste } from "react-icons/fa6";
import { invokeservice } from "../../../api/apiService";
export default function purchaseButton(props) {
  let navigate = useNavigate();



  const Web3 = require("web3");
  const web3 = new Web3(process.env.REACT_APP_RPC);

  let miner_type = props.miner;

  const [refAddress, setRefAddress] = useState("...");
  const [showbnberrorModal, setshowbnberrorModal] = useState(false);
  const [showtokerrorModal, setshowtokerrorModal] = useState(false);
  const [tokenapp, setTokenapp] = useState(true); //token approve button enabled
  const [loadapprove, setLoadapprove] = useState(false); //approve button loader
  const [processState, setprocessState] = useState({
    state: "...",
    data: null,
  });
  const [OTP, setOTP] = useState("");
  const [valid, setisValid] = useState("...");

  const handleClosebnberr = () => setshowbnberrorModal(false);
  const handleClosetokerr = () => setshowtokerrorModal(false);

  const identitycontractInstance = new web3.eth.Contract(
    dataVal.identityabi,
    process.env.REACT_APP_INDENTITY_ADDR
  );

  useEffect(() => {
    checkRef();

  }, []);

  useEffect(() => {
    console.log("props selected", props)
    if (props.selected != "...") {
      setTokenapp(false)
    }
  }, [props]);

  async function checkRef() {
    var wAddress = localStorage.getItem("acct");
    if (wAddress) {
      await identitycontractInstance.methods
        .fetchUser(localStorage.getItem("acct"))
        .call()
        .then((value) => {
          if (value.parent == "0x0000000000000000000000000000000000000000") {
            setisValid(false);
          } else {
            setisValid(true);
          }
        })
        .catch((error) => console.error(error));
    }
    return 1;
  }

  //to check if sufficient token balance is available
  async function checkBalance() {
    setprocessState({ state: "processing", data: null });


    var xbal = await web3.eth.getBalance(localStorage.getItem("acct"));
    if (parseFloat(web3.utils.fromWei(xbal, "ether")).toFixed(4) < 0.005) {
      setshowbnberrorModal(true);
      setprocessState({ state: "...", data: null });
    } else {
      if (props.selected == "egoldv1") {
        var tokenaddr = process.env.REACT_APP_EGOLD_ADDR;
      } else if (props.selected == "egoldv2") {
        var tokenaddr = process.env.REACT_APP_EGOLDV2_ADDR;
      } else if (props.selected == "busd") {
        var tokenaddr = process.env.REACT_APP_BUSD_ADDR;
      } else if (props.selected == "wbnb") {
        var tokenaddr = process.env.REACT_APP_WBNB_ADDR;
      } else if (props.selected == "cbk") {
        var tokenaddr = process.env.REACT_APP_CBK_ADDR;
      }

      const tokcontractInstance = new web3.eth.Contract(
        dataVal.tokenabi,
        tokenaddr
      );
      await tokcontractInstance.methods
        .balanceOf(localStorage.getItem("acct"))
        .call()
        .then((value) => {
          if (
            parseFloat(web3.utils.fromWei(value, "ether")) >=
            parseFloat(props.tokprice)
          ) {
            purchase()
          } else {
            setshowtokerrorModal(true);
            setprocessState({ state: "...", data: null });
          }
        })
    }
  }


  async function purchase() {


    try {

      const token = localStorage.getItem("accessToken");
      const user = localStorage.getItem("email");

      if (props.selected == "cbk")
        var buyData = {
          job: {
            type: "PurchaseUsingcashback",
            params: {
              useragent: navigator.userAgent,
              miner_type: miner_type,
              user: user,
              totp: localStorage.getItem("isoptionaltfa") == "true" ? OTP : ""
            },
          },
        };
      else
        var buyData = {
          job: {
            type: "PurchaseMinerV2",
            params: {
              useragent: navigator.userAgent,
              miner_type: miner_type,
              treasury: props.selected == "egoldv1" ? "EGOLD" : props.selected.toUpperCase(),
              user: user,
              totp: localStorage.getItem("isoptionaltfa") == "true" ? OTP : ""
            },
          },
        };
      console.log("minertytpe", buyData)
      var headers = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + String(token),
        },
      };
      invokeservice(buyData, headers).then((json) => {
        if (json.data.status == "failed") {
          setprocessState({
            state: "error",
            data: json.data.message,
          });
        } else {
          setprocessState({ state: "done", data: "Miner purchased successfully" });
        }
      })
    } catch (e) {
      console.log(e);
      setprocessState({ state: "error", data: JSON.stringify(e.message) });
    }

  }

  return (
    <>
      {" "}
      {valid == "..." ? (
        <div style={{ padding: "20px", textAlign: "center" }}>
          <Spinner />
        </div>
      ) : (
        <>
          {localStorage.getItem("isoptionaltfa") == "true" && (
            <div class="mb-4">
              <label for="receiver_address" class="form-label">
                Please enter 2FA code
              </label>
              <InputGroup>
                <Input
                  className="withdrawinput"
                  type="text"
                  name="receiver_address"
                  value={OTP}
                  id="receiver_address"
                  style={{
                    backgroundColor: "#fff",
                    borderRight: "0px",
                    color: "#000",
                    fontSize: "13px",
                    padding: "13px 12px",
                    borderRadius: "8px 0px 0px 8px",
                    border: "1px solid #E2E8F0",
                    borderRightColor: "#fff"
                  }}
                  onChange={(e) => {
                    setOTP(e.target.value);
                  }}
                />

                <InputGroupText
                  className="pastespan"
                  onClick={async () => {
                    setOTP(await navigator.clipboard.readText())
                  }}
                >
                  <FaRegPaste color="#4F6B75" size={23} />
                </InputGroupText>
              </InputGroup>
            </div>
          )}
          <div class="selectcurbtnsec">
            <button
              class="btn-color-primary mb20 paybtn"
              style={{ border: "0px", width: "100%" }}
              disabled={tokenapp}
              onClick={(e) => checkBalance()}
            >
              Pay Now
            </button>
          </div>
          {processState.state == "..." ? (
            ""
          ) : processState.state == "processing" ? (
            <LoaderCard />
          ) : processState.state == "done" ? (
            <ConfirmCard tx={processState.data} manage="true" />
          ) : (
            <ErrorCard err={processState.data} />
          )}

          {/* Insufficient bnb error modal  */}
          <Modal
            isOpen={showbnberrorModal}
            toggle={handleClosebnberr}
            style={{ marginTop: 150 }}
            classNames="errormodal"
            center
          >
            <ModalBody>
              <div style={{ textAlign: "center" }}>
                <AiOutlineInfoCircle color="#FF0000" size={48} />
                <>
                  <h2
                    style={{
                      fontSize: "19px",
                      fontWeight: 600,
                      color: "#232325",
                      padding: 10,
                    }}
                  >
                    Insufficient BNB
                  </h2>
                  <p
                    style={{
                      fontSize: 12,
                      lineHeight: "15px",
                      color: "#232325",
                      padding: 12,
                    }}
                  >
                    You need to have atleast 0.005 BNB in your wallet to pay for
                    transaction fees
                  </p>
                </>
                <div
                  style={{
                    background: "#FFC727",
                    padding: "10px 15px",
                    color: "#232325",
                    borderRadius: 4,
                    cursor: "pointer",
                  }}
                  onClick={handleClosebnberr}
                >
                  Okay
                </div>
              </div>
            </ModalBody>
          </Modal>

          {/* Insufficient token error modal  */}
          <Modal
            isOpen={showtokerrorModal}
            toggle={handleClosetokerr}
            style={{ marginTop: 150 }}
            classNames="errormodal"
            center
          >
            <ModalBody>
              <div style={{ textAlign: "center" }}>
                <AiOutlineInfoCircle color="#FF0000" size={48} />
                <>
                  <h2
                    style={{
                      fontSize: "19px",
                      fontWeight: 600,
                      color: "#232325",
                      padding: 10,
                    }}
                  >
                    Insufficient {props.selected.toUpperCase()}
                  </h2>
                  <p
                    style={{
                      fontSize: 12,
                      lineHeight: "15px",
                      color: "#232325",
                      padding: 12,
                    }}
                  >
                    You need to have atleast {props.tokprice}{" "}
                    {props.selected.toUpperCase()} in your wallet to pay for the
                    miner
                  </p>
                </>
                <div
                  style={{
                    background: "#FFC727",
                    padding: "10px 15px",
                    color: "#232325",
                    borderRadius: 4,
                    cursor: "pointer",
                  }}
                  onClick={handleClosetokerr}
                >
                  Okay
                </div>
              </div>
            </ModalBody>
          </Modal>
        </>
      )}
    </>
  );
}
