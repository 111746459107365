import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function MinerCard(props) {
  let navigate = useNavigate();

  const dailySupply = 6849.315068;
  const [netHP, setNetHP] = useState("...");
  const [egPrice, setEgPrice] = useState("...");
  const [calculated, setCalculated] = useState("...");

  useEffect(() => {
    getSummary();
  }, []);

  useEffect(() => {
    setCalculated(parseFloat((props.power / netHP) * dailySupply));
  }, [netHP, props.power]);

  const getSummary = async () => {
    const url = "https://egold-treasury.tagdev.info/v1/summary";

    fetch(url)
      .then(function (response) {
        return response.json();
      })
      .then(function (newData) {
        console.log("---", newData);
        setNetHP(newData.hashPowerSold);
      })
      .catch(function (error) {
        console.log("Requestfailed", error);
      });

    const urltd =
      "https://egold-marketdata.herokuapp.com/v1/summary/getLatestTrades/20/";
    //fetch 24 hour market data
    fetch(urltd)
      .then(function (response) {
        return response.json();
      })
      .then(function (newData) {
        setEgPrice(parseFloat(newData[0].TAG_USD).toFixed(4));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //to not round values while showing balance
  function noround(number, decimalDigits) {
    const powerOfTen = Math.pow(10, decimalDigits);
    const formattedNumber = Math.floor(number * powerOfTen) / powerOfTen;
    if (Number.isInteger(formattedNumber)) {
      return roundzeroes(formattedNumber, 2);
    } else return formattedNumber;
  }

  //to round decimal points with zeroes
  function roundzeroes(val, x) {
    var float = parseFloat(val).toFixed(18);
    var num = float.toString();
    var n = num.slice(0, num.indexOf(".") + (x + 1));
    return n;
  }

  return (
    <>
      <p class="headingtext2">Miner Specifications</p>
      <div class="table-responsive">
        <table class="table table-bordered  minerspectable">
          <tr>
            <td class="text-center lightblue-cell-bg">Miner Type</td>
            <td class="text-center fw700">Egold Miner {props.name}</td>
          </tr>
          <tr>
            <td class="text-center lightblue-cell-bg">Mining power</td>
            <td class="text-center fw700">{props.power} MHS</td>
          </tr>
          <tr>
            <td class="text-center lightblue-cell-bg">Max Claims</td>
            <td class="text-center fw700">10</td>
          </tr>
          <tr>
            <td class="text-center lightblue-cell-bg">Mining Period</td>
            <td class="text-center fw700">Unlimited</td>
          </tr>
          <tr>
            <td class="text-center lightblue-cell-bg">Depreciation</td>
            <td class="text-center fw700">5% Per Claim</td>
          </tr>
          <tr>
            <td class="text-center lightblue-cell-bg">Hosting Fee </td>
            <td class="text-center fw700">1%</td>
          </tr>
          <tr>
            <td class="text-center lightblue-cell-bg">Price</td>
            <td class="text-center fw700 green">${props.price}</td>
          </tr>
        </table>
      </div>

      <p class="headingtext2">Miner Profitability (1st claim)</p>
      <div class="table-responsive">
        <table class="table table-bordered  minerspectable">
          <thead>
            <tr>
              <th class="lightblue-cell-bg"></th>
              <th class="text-center lightblue-cell-bg">
                1 day <span>(Egold)</span>
              </th>
              <th class="text-center lightblue-cell-bg">
                30 days <span>(Egold)</span>
              </th>
              <th class="text-center lightblue-cell-bg">
                365 days <span>(Egold)</span>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td class="text-center lightblue-cell-bg">Production</td>
              <td class="text-center fw700">
                {isNaN(calculated)
                  ? "..."
                  : noround(parseFloat(calculated) * 1, 4)}
              </td>
              <td class="text-center fw700">
                {isNaN(calculated) ? "..." : noround(calculated * 30, 4)}
              </td>
              <td class="text-center fw700">
                {isNaN(calculated) ? "..." : noround(calculated * 365, 4)}
              </td>
            </tr>
            <tr>
              <td class="text-center lightblue-cell-bg">Mining Fuel</td>
              <td class="text-center red fw700">
                {isNaN(calculated)
                  ? "..."
                  : "- " + noround(0.5 * calculated * 1, 4)}
              </td>
              <td class="text-center red fw700">
                {isNaN(calculated)
                  ? "..."
                  : "- " + noround(0.5 * calculated * 30, 4)}
              </td>
              <td class="text-center red fw700">
                {isNaN(calculated)
                  ? "..."
                  : "- " + noround(0.5 * calculated * 365, 4)}
              </td>
            </tr>
            <tr>
              <td class="text-center lightblue-cell-bg">Depreciation</td>
              <td class="text-center red fw700">
                {isNaN(calculated) ? "..." : "- 0.0000"}
              </td>
              <td class="text-center red fw700">
                {isNaN(calculated) ? "..." : "- 0.0000"}
              </td>
              <td class="text-center red fw700">
                {isNaN(calculated) ? "..." : "- 0.0000"}
              </td>
            </tr>
            <tr>
              <td class="text-center lightblue-cell-bg">Hosting</td>
              <td class="text-center red fw700">
                {isNaN(calculated)
                  ? "..."
                  : "- " + noround(0.01 * calculated * 1, 4)}
              </td>
              <td class="text-center red fw700">
                {isNaN(calculated)
                  ? "..."
                  : "- " + noround(0.01 * calculated * 30, 4)}
              </td>
              <td class="text-center red fw700">
                {isNaN(calculated)
                  ? "..."
                  : "- " + noround(0.01 * calculated * 365, 4)}
              </td>
            </tr>
            <tr>
              <td class="text-center lightblue-cell-bg">Est. Yield</td>
              <td class="text-center fw700">
                {isNaN(calculated) ? "..." : noround(0.49 * calculated * 1, 4)}
              </td>
              <td class="text-center fw700">
                {isNaN(calculated) ? "..." : noround(0.49 * calculated * 30, 4)}
              </td>
              <td class="text-center fw700">
                {isNaN(calculated)
                  ? "..."
                  : noround(0.49 * calculated * 365, 4)}
              </td>
            </tr>
            <tr>
              <td class="text-center lightblue-cell-bg">Est. Profit</td>
              <td class="text-center green fw700">
                $
                {egPrice == "..." || isNaN(calculated)
                  ? "..."
                  : noround(egPrice * 0.49 * calculated * 1, 4)}
              </td>
              <td class="text-center green fw700">
                $
                {egPrice == "..." || isNaN(calculated)
                  ? "..."
                  : noround(egPrice * 0.49 * calculated * 30, 4)}
              </td>
              <td class="text-center green fw700">
                $
                {egPrice == "..." || isNaN(calculated)
                  ? "..."
                  : noround(
                      egPrice *
                        0.49 *
                        (props.power / netHP) *
                        dailySupply *
                        365,
                      4
                    )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
}
