import React, { useEffect, useState } from "react";
import BackButton from "./Buttons/BackButton/BackButton";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import MinerDetails from "./MinerDetails/minerDetails";
import dataVal from "../data/Abis.json";
import { Spinner } from "reactstrap";


import MinerCard from "./MinerCard/MinerCard";

export default function Dets() {

  let navigate = useNavigate();
  let params = useParams();
  let location = useLocation();

  let miner_type = location.state;

  const [minerName, setMinerName] = useState("...");
  const [minerPrice, setMinerPrice] = useState("...");
  const [minerHash, setMinerHash] = useState("...");
  const [randomMiners, setrandomMiners] = useState([]);

  const Web3 = require("web3");
  const web3 = new Web3(process.env.REACT_APP_RPC);

  useEffect(() => {
    getMinerInfo();
    let randMiners = getRandomMiners(miner_type);
    setrandomMiners(randMiners);
  }, []);

  useEffect(() => {
    getMinerInfo();
    let randMiners = getRandomMiners(miner_type);
    setrandomMiners(randMiners);
  }, [location.state]);

  const getMinerInfo = async () => {
    const contractInstance = new web3.eth.Contract(
      dataVal.mregistryabi,
      process.env.REACT_APP_MINER_REGISTRY
    );

    await contractInstance.methods
      .fetchMinerInfo(miner_type)
      .call()
      .then((res) => {
        setMinerName(res.name);
        setMinerPrice(parseInt(res.minerBaseRate));
        setMinerHash(parseInt(res.hashRate));
      });
  };

  const miners = [21, 22, 23, 24, 25, 26, 27, 28];

  function getRandomMiners(notMiner) {
    const filteredArray = miners.filter((item) => item !== notMiner);

    if (filteredArray.length < 2) {
      return null; // Not enough available values
    }

    const randomIndices = [];
    while (randomIndices.length < 2) {
      const randomIndex = Math.floor(Math.random() * filteredArray.length);
      if (!randomIndices.includes(randomIndex)) {
        randomIndices.push(randomIndex);
      }
    }

    return [filteredArray[randomIndices[0]], filteredArray[randomIndices[1]]];
  }
  
  const oldPrice = {
    22: 25,
    41: 50,
    90: 100,
    441: 490,
    864: 960,
    2115: 2350,
    4140: 4600,
    8100: 9000,
  };

  return (
    <>
      <div class="mainsection">
        <div class="tabsec secpadding lightgrey-bg brtlr">
          <BackButton Title="Miner Details" ReRoute="buy" />
          <div class="detailCard secpadding mb20">
            <div class="minerAvailableCard mb10">
              <div class="minerAvailableTitle">
                EGOLD Miner <span class="">{minerName}</span>
              </div>

              <div class="minerAvailableImage">
                {minerName == "..." ? (
                  <div style={{ padding: "50px" }}>
                    <Spinner />
                  </div>
                ) : (
                  <img
                    src={require("../images/miners/" + minerName + ".png")}
                    class="img-fluid"
                  />
                )}
              </div>

              <a class="minerAvailablebtn">Power : {minerHash} mhs</a>
              <a
            class="minerAvailablebtn"
            style={{ gap: "5px", alignItems: "baseline" }}
          >
            Price : <span style={{ fontWeight: "500" }}>${minerPrice}</span>{" "}
            {oldPrice[minerPrice] ? (
              <div>
                <span
                  style={{
                    textDecoration: "line-through",
                    fontSize: "12px",
                    color: "#fb3131",
                  }}
                >
                  {" "}
                  ${oldPrice[minerPrice]}
                </span>
              </div>
            ) : (
              ""
            )}
          </a>
            </div>
            {minerName !== "..." && (
              <a
                class="btn-color-primary mb20"
                onClick={() => {
                  navigate("/buy/" + minerName + "/selectpay", {
                    state: miner_type,
                  });
                }}
                style={{ cursor: "pointer" }}
              >
                Buy Now
              </a>
            )}
            <MinerDetails
              name={minerName}
              power={minerHash}
              price={minerPrice}
            />
          </div>
          <div class="otherminersec">
            <p class="headingtext2">Other miners</p>

            <div class="row">
              {randomMiners.length > 0 ? (
                randomMiners.map((item, index) => <MinerCard type={item} />)
              ) : (
                <div style={{ padding: "50px" }}>
                  <Spinner />
                </div>
              )}
            </div>
          </div>
        </div>
        <div class="lightblue-bg secpadding brblr mb20">
          <p class="text1">
            Profitibility statistic displayed above are based on current network
            mining stats. These numbers evolve as more participants join the
            network.
          </p>
        </div>
      </div>
    </>
  );
}
