import React from "react";
import fail from "./img/fail.png";
import { useNavigate, useParams } from "react-router-dom";
import { FaTriangleExclamation } from "react-icons/fa6";
import { IoArrowBackCircleOutline, } from "react-icons/io5";
import EgoldLogo from "../../../images/Egold_logo.svg"
const SuccessFailure = () => {
    let success_failure = true;
    let params = useParams();
    let emailtoken = params.query;
    return (
        <> <br />
            <main className='loginsection' >
                <div class="mainsection ">
                    <div class="logintabsec">

                        <div class="loginsec" >
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: 10 }}>
                                <img src={EgoldLogo} />
                            </div>

                            <div className="my-5" style={{ textAlign: "center" }}>
                                <FaTriangleExclamation size={60} color="#ef7e02" />
                                <h3 className=" font-bold text-[16px] mb-5 text-center h2color" style={{ color: "#717070", fontSize: 22, marginTop: 10 }}>
                                    {emailtoken}
                                    <br />

                                </h3>

                                {/* <img height="150px" src={fail} /> */}

                                <div style={{ justifyContent: "center", display: "flex" }}>
                                    <a class="minerAvailablebtn" style={{ marginTop: 20, padding: 8, width: "50%", cursor: "pointer", display: "flex", gap: 10, border: 'unset', }} href="/">
                                        <IoArrowBackCircleOutline color="black" size={22} />Back to login</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};

export default SuccessFailure;
