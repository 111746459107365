import { json } from "react-router-dom";
import ApiHelper from "./apiHelper";

/* Authentication */

export const logIn = (data) => {
  return ApiHelper.post(`${process.env.REACT_APP_APIEND}/api/auth/login`, data);
};
export const resetPW = (data) => {
  return ApiHelper.post(
    `${process.env.REACT_APP_APIEND}/api/auth/resetpassword`,
    data
  );
};

export const getBNBhistory = (headers) => {
  return ApiHelper.getwithheaders(
    `${process.env.REACT_APP_APIEND}/getbnbtransactions`,
    headers
  );
};
export const getAirdropClaimStatus = (headers, data) => {
  return ApiHelper.getwithheaders(
    `${process.env.REACT_APP_APIEND}/getairdropstatus/` + data,
    headers
  );
};
export const getBUSDhistory = (headers) => {
  return ApiHelper.getwithheaders(
    `${process.env.REACT_APP_APIEND}/getbusdtransactions`,
    headers
  );
};
export const getEGOLDhistory = (headers) => {
  return ApiHelper.getwithheaders(
    `${process.env.REACT_APP_APIEND}/getegoldtransactions`,
    headers
  );
};
export const cattransactionHistory = (cat, page, headers) => {
  return ApiHelper.getwithheadersanddata(
    `${process.env.REACT_APP_APIEND}/transactionsbycategory/?page=` +
    page +
    "&category=" +
    cat,
    headers
  );
};
export const getparent = (headers) => {
  return ApiHelper.getwithheaders(
    `${process.env.REACT_APP_APIEND}/getreferer`,
    headers
  );
};
export const airdropCall = (data, headers) => {
  return ApiHelper.postwithheaders(
    `${process.env.REACT_APP_APIEND}/registerforairdrop`,
    data,
    headers
  );
};

export const checkStatus = (data) => {
  return ApiHelper.get(
    `${process.env.REACT_APP_APIEND}/api/auth/check_status/` + data
  );
};

export const resendCode = (data) => {
  return ApiHelper.get(
    `${process.env.REACT_APP_APIEND}/api/auth/regenemailveri/` + data
  );
};


export const getqrcode = (headers) => {
  return ApiHelper.getwithheaders(
    `${process.env.REACT_APP_APIEND}/api/auth/enable_2fa`,
    headers
  );
};
export const checkTwitter = (data, headers) => {
  return ApiHelper.getwithheadersanddata(
    `${process.env.REACT_APP_APIEND}/checksmtwitter/` + data,
    headers
  );
};
export const checkTelegram = (data, headers) => {
  return ApiHelper.getwithheadersanddata(
    `${process.env.REACT_APP_APIEND}/checksmtelegram/` + data,
    headers
  );
};
export const getAirdopData = (headers) => {
  return ApiHelper.getwithheaders(
    `${process.env.REACT_APP_APIEND}/getairdropregdata`,
    headers
  );
};
export const getadClaimedStatus = (headers) => {
  return ApiHelper.getwithheaders(
    `${process.env.REACT_APP_APIEND}/getadclaimedstatus`,
    headers
  );
};
export const getnotifications = (headers) => {
  return ApiHelper.getwithheaders(
    `${process.env.REACT_APP_APIEND}/logs`,
    headers
  );
};
export const getairdropstatus = (headers) => {
  return ApiHelper.getwithheaders(
    `${process.env.REACT_APP_APIEND}/getairdropregstatus`,
    headers
  );
};
export const emailverification = (data) => {
  return ApiHelper.post(
    `${process.env.REACT_APP_APIEND}/api/auth/mailverification`,
    data
  );
};
export const getoptional2fastat = (headers) => {
  return ApiHelper.getwithheaders(
    `${process.env.REACT_APP_APIEND}/api/auth/get_optional_tfa_status`,
    headers
  );
};
export const registeruser = (data) => {
  return ApiHelper.post(
    `${process.env.REACT_APP_APIEND}/api/auth/register`,
    data
  );
};
export const enabletwofactor = (data, headers) => {
  return ApiHelper.postwithheaders(
    `${process.env.REACT_APP_APIEND}/api/auth/verify_2fa_setup`,
    data,
    headers
  );
};
export const confirmresetpw = (data, headers) => {
  return ApiHelper.postwithheaders(
    `${process.env.REACT_APP_APIEND}/api/auth/updatepassword`,
    data,
    headers
  );
};
export const invokeservice = (data, headers) => {
  return ApiHelper.postwithheaders(
    `${process.env.REACT_APP_APIEND}/api/auth/invokeservice`,
    data,
    headers
  );
};
export const enableoptionaltfa = (data, headers) => {
  return ApiHelper.postwithheaders(
    `${process.env.REACT_APP_APIEND}/api/auth/enable_optional_tfa`,
    data,
    headers
  );
};
export const disableoptionaltfa = (data, headers) => {
  return ApiHelper.postwithheaders(
    `${process.env.REACT_APP_APIEND}/api/auth/disable_optional_tfa`,
    data,
    headers
  );
};
export const sendwithdrawemail = (data, headers) => {
  return ApiHelper.postwithheaders(
    `http://164.92.64.33/api/auth/requestemailotp`,
    data,
    headers
  );
};
export const refreshtoken = (headers) => {
  return ApiHelper.postwithonlyheaders(
    `${process.env.REACT_APP_APIEND}/api/auth/refresh`,
    headers
  );
};
export const verifyPW = (data) => {
  return ApiHelper.post(
    `${process.env.REACT_APP_APIEND}/api/auth/verifyPw`,
    data
  );
};
export const transactionHistory = (page, headers) => {
  console.log("HERE TRANSACT", headers);
  return ApiHelper.getwithheadersanddata(
    `${process.env.REACT_APP_APIEND}/transactions/` + page,
    headers
  );
};
export const getunreadnotifications = (headers) => {
  return ApiHelper.getwithheaders(
    `${process.env.REACT_APP_APIEND}/unreadnotifications`,
    headers
  );
};
export const updatelastreadnotification = (data, headers) => {
  return ApiHelper.putwithheaders(
    `${process.env.REACT_APP_APIEND}/updatelastread`,
    data,
    headers
  );
};
// export const changepw = (data, headers) => {
//   return ApiHelper.putwithheaders(
//     `${process.env.REACT_APP_APIEND}api/auth/changepw`,
//     data,
//     headers
//   );
// };
