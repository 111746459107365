import React from "react";
import "./index.css";

import Header from "./components/Header/Header";
import HeaderNoCard from "./components/Header/HeaderNoCard";
import HeaderNoWallet from "./components/Header/HeaderNoWallet";
import Footer from "./components/Footer/Footer";
import Buy from "./components/AppBuy";
import MinerDetails from "./components/AppMinerDetails";
import SelectPay from "./components/AppSelectPay";
import Manage from "./components/AppManage";
import Transfer from "./components/AppTransfer";
import Refer from "./components/AppRefer";
import AppRefCalculator from "./components/AppRefCalculator";
import AppRefCompPlan from "./components/AppRefCompPlan";
import AppRefCollect from "./components/AppRefCollect";
import AppRefDownline from "./components/AppRefDownline";
import Stats from "./components/AppStats";
import Wallet from "./components/Wallet/wallet";
import Send from "./components/Wallet/send";
import Recieve from "./components/Wallet/receive";
import QuickStart from "./components/QuickStart";
import QuickTrack from "./components/QuickTrack";
import QuickRules from "./components/QuickRules"
import SelectToken from "./components/Wallet/SelectToken";
import AppChangePassword from "./components/AppChangePassword"
import ProtectedRoute from "./components/routes/protectedRoutes";
import Login from "./components/Login/Login";
import EmailSuccess from "./components/Login/EmailVerification/Success"
import EmailFail from "./components/Login/EmailVerification/Failure"
import CheckMail from "./components/Login/EmailVerification/checkMail"
import VerifyMail from "./components/Login/EmailVerification/verifyMail.jsx"
import ForgotPassword from "./components/Login/ForgotPassword";
import Register from "./components/Login/Register";
import ActivateFA from "./components/Login/ActivateFA";
import ErrorPage from "./components/ErrorPage";
import ResetPassword from "./components/Login/ResetPassword";
import Notifications from "./components/AppNotifications";
import SelNotification from "./components/AppNotiSelected";
import AppMiningCalc from "./components/AppMiningCalc"
import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { StrictMode } from "react";

// To disable log on production
if (process.env.NODE_ENV === "production") {
  console.log = () => { };
  console.error = () => { };
  console.debug = () => { };
}

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <>

        <div style={{ flex: "1 1" }}>  <Login /></div>

        <Footer />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/forgotpw",
    element: (
      <>
        <div style={{ flex: "1 1" }}>
          <ForgotPassword />
        </div>

        <Footer />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/forgotpassword/:query",
    element: (
      <>
        <div style={{ flex: "1 1" }}>
          <ResetPassword />
        </div>

        <Footer />
      </>
    ),
  },
  {
    path: "/emailverifysuccess",
    element: (
      <>
        <div style={{ flex: "1 1" }}>
          <EmailSuccess />
        </div>

        <Footer />
      </>
    ),
  },
  {
    path: "/emailverifyfailure",
    element: (
      <>
        <div style={{ flex: "1 1" }}>
          <EmailFail />
        </div>

        <Footer />
      </>
    ),
  },
  {
    path: "/failure/:query",
    element: (
      <>
        <div style={{ flex: "1 1" }}>
          <CheckMail />
        </div>

        <Footer />
      </>
    ),
  },


  // {
  //   path: "/quickrules",
  //   element: (
  //     <ProtectedRoute>
  //       <div style={{ flex: "1 1" }}>
  //         <Header />
  //         <QuickRules />
  //       </div>

  //       <Footer />
  //     </ProtectedRoute>

  //   ),
  //   errorElement: <ErrorPage />,
  // },


  {
    path: "/register",
    element: (
      <>
        <div style={{ flex: "1 1" }}>
          <Register />
        </div>

        <Footer />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/changepw",
    element: (
      <ProtectedRoute>
        <div style={{ flex: "1 1" }}>
          <HeaderNoWallet />
          <AppChangePassword />
        </div>

        <Footer />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
  },


  {
    path: "/enableauth",
    element: (
      <>
        <div style={{ flex: "1 1" }}>
          <ActivateFA />
        </div>

        <Footer />
      </>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/buy",
    element: (
      <ProtectedRoute>
        <div style={{ flex: "1 1" }}>
          <Header />
          <Buy />
        </div>

        <Footer />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/notifications",
    element: (
      <ProtectedRoute>
        <div style={{ flex: "1 1" }}>
          <Header />
          <Notifications />
        </div>

        <Footer />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/selectednotification",
    element: (
      <ProtectedRoute>
        <div style={{ flex: "1 1" }}>
          <Header />
          <SelNotification />
        </div>

        <Footer />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/selecttoken",
    element: (
      <ProtectedRoute>
        <div style={{ flex: "1 1" }}>
          <HeaderNoWallet />
          <SelectToken />
        </div>

        <Footer />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
  },
  // {
  //   path: "/quickstart",
  //   element: (
  //     <ProtectedRoute>
  //       <div style={{ flex: "1 1" }}>
  //         <Header />
  //         <QuickStart />
  //       </div>

  //       <Footer />
  //     </ProtectedRoute>
  //   ),
  //   errorElement: <ErrorPage />,
  // },
  // {
  //   path: "/quicktrack/:id",
  //   element: (
  //     <ProtectedRoute>
  //     <div style={{ flex: "1 1" }}>
  //       <Header />
  //       <QuickTrack />
  //     </div>

  //     <Footer />
  //   </ProtectedRoute>
  //   ),
  //   errorElement: <ErrorPage />,
  // },
  {
    path: "/wallet",
    element: (
      <ProtectedRoute>
        <div style={{ flex: "1 1" }}>
          <HeaderNoWallet />
          <Wallet />
        </div>
        <Footer />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/send",
    element: (
      <ProtectedRoute>
        <div style={{ flex: "1 1" }}>
          <HeaderNoWallet />
          <Send />
        </div>

        <Footer />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/recieve",
    element: (
      <ProtectedRoute>
        <div style={{ flex: "1 1" }}>
          <HeaderNoWallet />
          <Recieve />
        </div>

        <Footer />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
  },

  {
    path: "/buy/:type",
    element: (
      <ProtectedRoute>
        <div style={{ flex: "1 1" }}>
          <Header />
          <MinerDetails />
        </div>

        <Footer />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/buy/:type/selectpay",
    element: (
      <ProtectedRoute>
        <div style={{ flex: "1 1" }}>
          <Header />
          <SelectPay />
        </div>

        <Footer />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/manage",
    element: (
      <ProtectedRoute>
        <div style={{ flex: "1 1" }}>
          <Header />
          <Manage />
        </div>

        <Footer />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/transfer/:id",
    element: (
      <ProtectedRoute>
        <div style={{ flex: "1 1" }}>
          <Header />
          <Transfer />
        </div>

        <Footer />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/refer",
    element: (
      <ProtectedRoute>
        <div style={{ flex: "1 1" }}>
          <Header />
          <Refer />
        </div>

        <Footer />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/refcalculator",
    element: (
      <ProtectedRoute>
        <div style={{ flex: "1 1" }}>
          <Header />
          <AppRefCalculator />
        </div>

        <Footer />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/compensationplan",
    element: (
      <ProtectedRoute>
        <div style={{ flex: "1 1" }}>
          <Header />
          <AppRefCompPlan />
        </div>

        <Footer />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/collectearnings",
    element: (
      <ProtectedRoute>
        <div style={{ flex: "1 1" }}>
          <Header />
          <AppRefCollect />
        </div>

        <Footer />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/downlinetree",
    element: (
      <ProtectedRoute>
        <div style={{ flex: "1 1" }}>
          <HeaderNoCard />
          <AppRefDownline />
        </div>

        <Footer />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/stats",
    element: (
      <ProtectedRoute>
        <div style={{ flex: "1 1" }}>
          <Header />
          <Stats />
        </div>

        <Footer />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/calculator",
    element: (
      <>
        <div style={{ flex: "1 1" }}>
          <Header />
          <AppMiningCalc />

        </div>
        <Footer />
      </>

    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/verifymail",
    element: (
      <>
        <div style={{ flex: "1 1" }}>
          <VerifyMail />
        </div>

        <Footer />
      </>
    ),
  },
]);



const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

const isSiteDown = false;

root.render(
  <StrictMode>
    <div className="wrapper">
      <RouterProvider router={router} />
    </div>
  </StrictMode>
);
