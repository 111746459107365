import React, { useEffect, useState } from 'react'
import EgoldLogo from "../../images/LoginEgold.png"
import {
    Button,
    Modal,
    ModalHeader,
    Spinner,
    Input,
    InputGroup,
    InputGroupText,
} from "reactstrap";
import BackButton from '../Buttons/BackButton/BackButton'
import { useNavigate } from "react-router-dom";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import LoaderCard from "../LoaderCard/LoaderCard";
import ConfirmCard from "../LoaderCard/ConfirmCard";
import ErrorCard from "../LoaderCard/ErrorCard";
import { registeruser } from "../../api/apiService";
import RegisterCard from '../LoaderCard/RegisterCard';
export default function Register() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [conpassword, setConpassword] = useState("");
    const [name, setName] = useState("");
    const [invitation, setinvitation] = useState("");
    const [errorMSG1, seterror1MSG] = useState({ status: null, message: "" });
    const [pweye, setPweye] = useState(true);
    const [errorMSG, seterrorMSG] = useState({ status: null, message: "" });
    const [processState, setprocessState] = useState({
        state: "...",
        data: null,
    });
    let history = useNavigate();


    async function RegisternewUser(e) {
        e.preventDefault();
        setprocessState({ state: "processing", data: null });
        if (
            name.length &&
            email.length &&
            password.length &&
            conpassword.length &&
            invitation.length > 0
        ) {
            registerAfter();
        } else {
            setprocessState({
                state: "error",
                data: "Please enter details",
            });
        }
    }
    async function registerAfter() {
        let registerData = {
            name: name,
            email: email,
            role: "string",
            created_at: "2022-11-06T02:33:35.999Z",
            updated_at: "2022-11-06T02:33:35.999Z",
            verified: false,
            tfa_enabled: false,
            tfa_secret: "string",
            password: password,
            passwordConfirm: conpassword,
            referer: invitation,
            origin: "w2shop"
        };
        registeruser(registerData).then((json) => {
            if (json.data.status == "success") {
                localStorage.setItem("email", email)
                setName("");
                setEmail("");
                setPassword("");
                setConpassword("");
                history("/verifymail")
                // setprocessState({ state: "done", data: "Registered Successfully" });
            } else {
                setprocessState({
                    state: "error",
                    data: json.data.detail,
                });
            }
        });
    }
    useEffect(() => {
        if (
            localStorage.getItem("isAuthenticated") === "true" &&
            localStorage.getItem("twoFaStatus") === "true"
        ) {
            history("/buy");
        }
    }, []);

    return (
        <main className='loginsection'>
            <div class="mainsection">
                <div class="tabsec" style={{ padding: 30, background: "#F5F5F5", borderRadius: 8 }}>
                    <BackButton Title="Sign Up" ReRoute="-1" />
                    <div class="loginsec">
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: 10 }}>
                            <img src={EgoldLogo} />
                        </div>
                        <div style={{ marginTop: 30, padding: 10 }}>
                            <label for="downline_rank" class="form-label" style={{ color: "#2D3748", fontSize: 15 }}>
                                Name
                            </label>
                            <InputGroup>
                                <Input
                                    className="withdrawinput"
                                    type="text"
                                    name="amount"
                                    id="email"
                                    style={{
                                        backgroundColor: "#fff",
                                        border: " 0.489247px solid #dee2e6",

                                        color: "#000",
                                        fontSize: "13px",
                                        padding: "13px 12px",
                                        borderRadius: "8px",
                                    }}
                                    value={name}
                                    onChange={(email) => {
                                        setName(email.target.value);
                                    }}
                                />
                            </InputGroup>
                        </div>
                        <div style={{ marginTop: 5, padding: 10 }}>
                            <label for="downline_rank" class="form-label" style={{ color: "#2D3748", fontSize: 15 }}>
                                Email address
                            </label>
                            <InputGroup>
                                <Input
                                    className="withdrawinput"
                                    type="text"
                                    name="amount"
                                    id="email"
                                    style={{
                                        backgroundColor: "#fff",
                                        border: " 0.489247px solid #dee2e6",

                                        color: "#000",
                                        fontSize: "13px",
                                        padding: "13px 12px",
                                        borderRadius: "8px",
                                    }}
                                    value={email}
                                    onChange={(email) => {
                                        setEmail(email.target.value);
                                    }}
                                />
                            </InputGroup>
                        </div>
                        <div style={{ marginTop: 5, padding: 10 }}>
                            <label for="downline_rank" class="form-label" style={{ color: "#2D3748", fontSize: 15 }}>
                                Password
                            </label>
                            <InputGroup>
                                <Input
                                    className={errorMSG1.status ? "passwordboxerror" : "passwordbox"}
                                    name="amount"
                                    type={"password"}
                                    id="pw"
                                    value={password}
                                    style={{ borderRight: ".489247px solid #dee2e6", borderRadius: "8px" }}
                                    onChange={(password) => {
                                        setPassword(password.target.value);
                                        password.target.value.length >= 8 ? seterror1MSG({ status: false, message: " " }) : seterror1MSG({ status: true, message: "Passwords require 8 characters" })
                                    }}
                                />

                            </InputGroup>
                            {errorMSG1.status ? (
                                <p className="errorbox">{errorMSG1.message}</p>
                            ) : null}
                        </div>
                        <div style={{ marginTop: 5, padding: 10 }}>
                            <label for="downline_rank" class="form-label" style={{ color: "#2D3748", fontSize: 15 }}>
                                Confirm Password
                            </label>
                            <InputGroup>
                                <Input
                                    className={errorMSG.status ? "passwordboxerror" : "passwordbox"}
                                    name="amount"
                                    type={pweye ? "password" : "text"}
                                    id="pw"
                                    value={conpassword}
                                    onChange={(conpassword) => {
                                        setConpassword(conpassword.target.value);
                                        conpassword.target.value == password ? seterrorMSG({ status: false, message: " " }) : seterrorMSG({ status: true, message: "Passwords do not match" })
                                    }}
                                />

                                <InputGroupText
                                    className={errorMSG.status ? "inputiconerror" : "inputicon"}
                                    onClick={() => {
                                        setPweye(!pweye)
                                    }}
                                >
                                    {pweye ? <AiFillEyeInvisible color='#4F6B75' size={24} /> : <AiFillEye color='#4F6B75' size={24} />}
                                </InputGroupText>
                            </InputGroup>
                            {errorMSG.status ? (
                                <p className="errorbox">{errorMSG.message}</p>
                            ) : null}
                        </div>
                        <div style={{ marginTop: 5, padding: 10 }}>
                            <label for="downline_rank" class="form-label" style={{ color: "#2D3748", fontSize: 15 }}>
                                Invitation code
                            </label>
                            <InputGroup>
                                <Input
                                    className="withdrawinput"
                                    type="text"
                                    name="amount"
                                    id="email"
                                    value={invitation}
                                    onChange={(password) => {
                                        setinvitation(password.target.value);
                                    }}
                                    style={{
                                        backgroundColor: "#fff",
                                        border: " 0.489247px solid #dee2e6",

                                        color: "#000",
                                        fontSize: "13px",
                                        padding: "13px 12px",
                                        borderRadius: "8px",
                                    }}
                                />
                            </InputGroup>
                        </div>
                        <div style={{ marginTop: 20, padding: 10, marginBottom: 20 }}>
                            <button className='btnlogin' onClick={(e) => {
                                RegisternewUser(e);
                            }} style={{ color: "black" }}>
                                Sign Up
                            </button>

                        </div>

                        <span style={
                            {
                                color: "#232325", fontWeight: '400', fontSize: 14, display: "flex", gap: 8, textAlign: "center", justifyContent: "center"
                            }
                        }>
                            Already a user? <p style={{
                                color: "#FFC727",
                                fontWeight: "700",
                                fontSize: 16,
                                cursor: "pointer"

                            }}
                                onClick={() => {
                                    history("/")
                                }}
                            >
                                Sign in
                            </p>
                        </span>
                        {processState.state == "..." ? (
                            ""
                        ) : processState.state == "processing" ? (
                            <LoaderCard />
                        ) : processState.state == "done" ? (
                            <div className="convertsionbox" style={{ marginTop: 20 }}>
                                <RegisterCard tx={processState.data} />
                            </div>
                        ) : (
                            <div className="convertsionbox" style={{ marginTop: 20 }}>
                                <ErrorCard err={processState.data} />
                            </div>
                        )}

                    </div>

                </div>
            </div>
        </main>
    )
}
