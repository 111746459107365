import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import BackButton from "./Buttons/BackButton/BackButton";
import dataVal from "../data/Abis.json";
import { Spinner } from "reactstrap";

var STAKEFARM_ABIJSON = dataVal.stakefarmabi;
var STAKENAME_ABIJSON = dataVal.stakenameabi;

export default function Notifications() {
  const location = useLocation();
  const CONTRACT_ADDRESS = process.env.REACT_APP_MINER_REGISTRY;


  let transid = location.state._id;

  const Web3 = require("web3");
  const web3 = new Web3(process.env.REACT_APP_RPC);
  const [symbol, setsymbol] = useState("...");
  const [depFarm, setFarm] = useState("...");
  const [minerType, setminerType] = useState("...");
  const [noti, setNoti] = useState([]);
  const [loaded, setLoaded] = useState(true);
  const months = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "Jun",
    7: "Jul",
    8: "Aug",
    9: "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec",
  };
  useEffect(() => {
    getTX();
  }, []);
  useEffect(() => {
    getTX();
  }, [transid]);


  const getTX = () => {
    const token = localStorage.getItem("accessToken");
    console.log("tr-", transid);
    fetch("https://auth.egoldfarm.com/logInfo/" + transid, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + String(token),
      },
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.code == 200) {
          console.log("HEREH ", json);

          if (
            json.data.task !== "withdraw_bnb" &&
            json.data.params.hasOwnProperty("token")
          ) {
            checkToken(json.data.params);
          } else if (json.data.task == "depositOnfarms") {
            checksymbol(json.data.params.farmaddress);
          }

          setNoti(json.data);
          setLoaded(false);
        } else {
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // to get proper task name
  function checkTxTask(task, item) {
    console.log("TEASK HERE", task);
    if (task == "wallet_creation") {
      return "Wallet Creation";
    } else if (task == "claim") {
      return "Claim";
    } else if (task == "purcahse_miner") {
      checkminerType(item.params.miner_type)
      return "Purchase Miner";
    } else if (task == "stake_miner") {
      return "Stake Miner";
    } else if (task == "unstake_miner") {
      return "Unstake Miner";
    } else if (task == "transfer_miner") {
      return "Transfer Miner";
    } else if (task == "delete_miner") {
      return "Delete Miner";
    } else if (task == "withdraw_bnb") {
      return "BNB Withdraw";
    } else if (task == "withdraw_token") {
      return "Token Withdraw";
    } else if (task == "gift_miner") {
      return "Giveaway";
    } else if (task == "Deposit") {
      return "Deposit";
    } else if (task == "addLiquidity") {
      return "Add Liquidity";
    } else if (task == "swapTokenforToken") {
      if (item.params.to && item.params.from) {
        return item.params.from + " to " + item.params.to + " Swap";
      } else {
        return "Swap Token";
      }
    } else if (task == "removeLiquidity") {
      return "Remove Liquidity";
    } else if (task == "depositLPfarm") {
      return "LP Farm Deposit";
    } else if (task == "withdrawLPyeild") {
      return "LP Farm Withdraw";
    } else if (task == "claimFreeminer") {
      return "Claim Free Miner";
    } else if (task == "depositOnfarms") {
      return "Farm Deposit";
    } else if (task == "withdrawFromfarms") {
      return "Farm Withdraw";
    } else if (task == "convert_bnb_to_wnbnb") {
      return "Convert BNB to WBNB";
    } else if (task == "swapBnbforToken") {
      if (item.params.to == undefined) {
        return "BNB to Token Swap";
      } else {
        return "BNB to " + item.params.to + " Swap";
      }
    } else if (task == "swapTokenforBnb") {
      if (item.params.from == undefined) {
        return "Token to BNB Swap";
      } else {
        return item.params.from + " to BNB Swap";
      }
    } else if (task == "withdrawFromfarms") {
      return "Farm Withdraw";
    } else if (task == "cashback_claim") {
      return "Cashback";
    } else if (task == "mint_giveaway_miner") {
      return "Mint Giveaway miner"
    }
  }

  // to check notification time difference
  function checkNotiTime(prtime) {
    var Timestamp = new Date(prtime);
    let convertedDate = new Date(
      Timestamp.getTime() - Timestamp.getTimezoneOffset() * 60 * 1000
    );
    var month = convertedDate.getUTCMonth() + 1; //months from 1-12
    var day = convertedDate.getUTCDate();
    var year = convertedDate.getUTCFullYear();
    const hours = convertedDate.getHours();
    const minutes = convertedDate.getMinutes();

    var ddata =
      months[month] + " " + day + "," + year + " " + hours + " : " + minutes;

    return ddata;
  }
  function checkToken(token) {
    console.log("wwweee", token);
    if (token.token != null) {
      if (token.token.substring(0, 2) == "0x") {
        // return (String(token.tktype).toUpperCase())

        const farmContractNameInstance = new web3.eth.Contract(
          STAKENAME_ABIJSON,
          token.token
        );
        farmContractNameInstance.methods
          .symbol()
          .call(async function (err, ressymbol) {
            console.log("symbol", ressymbol);
            setsymbol(ressymbol);
          });
      } else {
        setsymbol(token.token);
        // return (token.token)
      }
    }
  }
  function checksymbol(addr) {
    const farmContractInstance = new web3.eth.Contract(STAKEFARM_ABIJSON, addr);
    farmContractInstance.methods.erc20().call(async function (err, res) {
      const farmContractNameInstance = new web3.eth.Contract(
        STAKENAME_ABIJSON,
        res
      );
      farmContractNameInstance.methods.symbol().call(async function (err, res) {
        setsymbol(res);
      });
      farmContractNameInstance.methods
        .name()
        .call(async function (err, resname) {
          setFarm(resname);
        });
    });
  }
  async function checkminerType(id) {
    const web3 = await new Web3(process.env.REACT_APP_RPC);
    const contractInstance = await new web3.eth.Contract(
      dataVal.mregistryabi,
      CONTRACT_ADDRESS
    );

    await contractInstance.methods
      .fetchMinerInfo(id)
      .call(function (err, res) {
        if (err) {
          console.log("An error occured", err);
          return;
        }
        setminerType(res.name);
      });
  }
  return (
    <>
      <div class="mainsection">
        <div class="tabsec secpadding lightgrey-bg brrr">
          <BackButton Title="Notification" ReRoute="-1" />
          <div class="detailCard secpadding mb20">
            {console.log("NOTI HERE", noti)}
            {loaded ? (
              <div style={{ textAlign: "center" }}>
                <Spinner />
              </div>
            ) : noti.length != 0 ? (
              <>
                <p class="inputdetailp mb15">
                  Type{" "}
                  <span class="float-end">{checkTxTask(noti.task, noti)}</span>
                </p>
                <p class="inputdetailp mb15">
                  Tx Hash{" "}
                  <span class="float-end">
                    {" "}
                    <a
                      target="_blank"
                      href={"https://bscscan.com/tx/" + noti.result}
                    >
                      {noti.status == "success"
                        ? noti.result.substring(0, 5) +
                        "...." +
                        noti.result.substring(noti.result.length - 7)
                        : "...."}
                    </a>
                  </span>
                </p>

                <p class="inputdetailp mb15">
                  Status{" "}
                  <span
                    class="float-end"
                    style={{ textTransform: "uppercase" }}
                  >
                    {noti.status}
                  </span>
                </p>
                <p class="inputdetailp mb15">
                  Timestamp{" "}
                  <span class="float-end">
                    {checkNotiTime(noti.created_time)}
                  </span>
                </p>

                {noti.task !== "Deposit" || noti.task !== "wallet_creation" ? (
                  noti.task == "claim" ? (
                    <ul className="buylist">
                      <li>
                        <label>Additional Information</label>
                      </li>

                      <p class="inputdetailp mb15">
                        Miner Type
                        <span class="float-end">
                          {" "}
                          G{noti.params.miner_type}
                        </span>
                      </p>

                      {noti.status != "failed" ? (
                        <p class="inputdetailp mb15">
                          Tx Fees
                          <span class="float-end">{noti.txn_fee}</span>
                        </p>
                      ) : null}
                    </ul>
                  ) : noti.task == "purcahse_miner" ? (
                    <ul className="buylist">
                      <li>
                        <label>Additional Information</label>
                      </li>
                      {/* error - only G */}
                      <p class="inputdetailp mb15">
                        Miner Type
                        <span class="float-end">
                          {" "}
                          {minerType}
                        </span>
                      </p>
                      <p class="inputdetailp mb15">
                        Miner Price
                        <span class="float-end">
                          {" "}
                          {noti.params.miner_type == 1
                            ? "20"
                            : noti.params.miner_type == 2
                              ? "100"
                              : noti.params.miner_type == 3
                                ? "500"
                                : "2500"}{" "}
                          BUSD
                        </span>
                      </p>

                      {noti.status != "failed" ? (
                        <p class="inputdetailp mb15">
                          Tx Fees
                          <span class="float-end">{noti.txn_fee}</span>
                        </p>
                      ) : null}
                    </ul>
                  ) : noti.task == "depositOnfarms" ? (
                    <ul className="buylist">
                      <li>
                        <label>Additional Information</label>
                      </li>

                      <p class="inputdetailp mb15">
                      Amount
                        <span class="float-end"> {noti.params.amount} EGOLD</span>
                      </p>
                      <p class="inputdetailp mb15">
                      Farm Address
                        <span class="float-end"> <a
                            target="_blank"
                            href={
                              "https://bscscan.com/address/" +
                              noti.params.farmaddress
                            }
                          >
                            {" "}
                            {depFarm}
                          </a></span>
                      </p>
                      {noti.status != "failed" ? (
                        <p class="inputdetailp mb15">
                          Tx Fees
                          <span class="float-end">{noti.txn_fee}</span>
                        </p>
                      ) : null}
                    </ul>
                  ) : noti.task == "stake_miner" ||
                    noti.task == "unstake_miner" ? (
                    <ul className="buylist">
                      <li>
                        <label>Additional Information</label>
                      </li>

                      <p class="inputdetailp mb15">
                        Miner ID
                        <span class="float-end"> {noti.params.miner_id}</span>
                      </p>

                      {noti.status != "failed" ? (
                        <p class="inputdetailp mb15">
                          Tx Fees
                          <span class="float-end">{noti.txn_fee}</span>
                        </p>
                      ) : null}
                    </ul>
                  ) : noti.task == "transfer_miner" ? (
                    <ul className="buylist">
                      <li>
                        <label>Additional Information</label>
                      </li>

                      <p class="inputdetailp mb15">
                        Miner ID
                        <span class="float-end"> {noti.params.miner_id}</span>
                      </p>

                      <p class="inputdetailp mb15">
                        To Address
                        <span class="float-end">
                          {" "}
                          <a
                            target="_blank"
                            href={
                              "https://bscscan.com/address/" +
                              noti.params.to_address
                            }
                          >
                            {" "}
                            {noti.params.to_address.substring(0, 5) +
                              "...." +
                              noti.params.to_address.substring(
                                noti.params.to_address.length - 7
                              )}
                          </a>
                        </span>
                      </p>

                      {noti.status != "failed" ? (
                        <p class="inputdetailp mb15">
                          Tx Fees
                          <span class="float-end">{noti.txn_fee}</span>
                        </p>
                      ) : null}
                    </ul>
                  ) : noti.task == "Deposit" ? (
                    <ul className="buylist">
                      <li>
                        <label>Additional Information</label>
                      </li>

                      <p class="inputdetailp mb15">
                        Amount
                        <span class="float-end">
                          {noti.params.value} {noti.params.currency}
                        </span>
                      </p>

                      <p class="inputdetailp mb15">
                        Sender Address
                        <span class="float-end">
                          <a
                            target="_blank"
                            href={
                              "https://bscscan.com/address/" +
                              noti.params.sender
                            }
                          >
                            {" "}
                            {noti.params.sender.substring(0, 5) +
                              "...." +
                              noti.params.sender.substring(
                                noti.params.sender.length - 7
                              )}
                          </a>
                        </span>
                      </p>

                      {noti.status != "failed" ? (
                        <p class="inputdetailp mb15">
                          Tx Fees
                          <span class="float-end">{noti.txn_fee}</span>
                        </p>
                      ) : null}
                    </ul>
                  ) : noti.task == "withdraw_bnb" ? (
                    <ul className="buylist">
                      <li>
                        <label>Additional Information</label>
                      </li>

                      <p class="inputdetailp mb15">
                        Amount
                        <span class="float-end"> {noti.params.value} BNB</span>
                      </p>

                      <p class="inputdetailp mb15">
                        To Address
                        <span class="float-end">
                          {" "}
                          <a
                            target="_blank"
                            href={
                              "https://bscscan.com/address/" + noti.params.to
                            }
                          >
                            {" "}
                            {noti.params.to.substring(0, 5) +
                              "...." +
                              noti.params.to.substring(
                                noti.params.to.length - 7
                              )}
                          </a>
                        </span>
                      </p>

                      {noti.status != "failed" ? (
                        <p class="inputdetailp mb15">
                          Tx Fees
                          <span class="float-end">{noti.txn_fee}</span>
                        </p>
                      ) : null}
                    </ul>
                  ) : noti.task == "swapBnbforToken" ? (
                    <ul className="buylist">
                      <li>
                        <label>Additional Information</label>
                      </li>

                      <p class="inputdetailp mb15">
                        From
                        <span class="float-end">
                          {" "}
                          {noti.params.fromAmount} {noti.params.from}
                        </span>
                      </p>
                      <p class="inputdetailp mb15">
                        To Amount
                        <span class="float-end">
                          {" "}
                          {noti.params.toAmount} {noti.params.to}
                        </span>
                      </p>

                      {noti.status != "failed" ? (
                        <p class="inputdetailp mb15">
                          Tx Fees
                          <span class="float-end">{noti.txn_fee}</span>
                        </p>
                      ) : null}
                    </ul>
                  ) : noti.task == "withdraw_token" ? (
                    <ul className="buylist">
                      <li>
                        <label>Additional Information</label>
                      </li>

                      <p class="inputdetailp mb15">
                        Amount
                        <span class="float-end">
                          {" "}
                          {noti.params.value} {symbol}
                        </span>
                      </p>

                      <p class="inputdetailp mb15">
                        To Address
                        <span class="float-end">
                          {" "}
                          <a
                            target="_blank"
                            href={
                              "https://bscscan.com/address/" + noti.params.to
                            }
                          >
                            {" "}
                            {noti.params.to.substring(0, 5) +
                              "...." +
                              noti.params.to.substring(
                                noti.params.to.length - 7
                              )}
                          </a>
                        </span>
                      </p>

                      {noti.status != "failed" ? (
                        <p class="inputdetailp mb15">
                          Tx Fees
                          <span class="float-end">{noti.txn_fee}</span>
                        </p>
                      ) : null}
                    </ul>
                  ) : noti.task == "swapTokenforBnb" ? (
                    <ul className="buylist">
                      <li>
                        <label>Additional Information</label>
                      </li>

                      <p class="inputdetailp mb15">
                        From Amount
                        <span class="float-end">
                          {" "}
                          {noti.params.fromAmount} {noti.params.from}
                        </span>
                      </p>
                      <p class="inputdetailp mb15">
                        To Amount
                        <span class="float-end">
                          {" "}
                          {parseFloat(noti.params.toAmount).toFixed(8)}{" "}
                          {noti.params.to}
                        </span>
                      </p>

                      {noti.status != "failed" ? (
                        <p class="inputdetailp mb15">
                          Tx Fees
                          <span class="float-end">{noti.txn_fee}</span>
                        </p>
                      ) : null}
                    </ul>
                  ) : noti.task == "removeLiquidity" ? (
                    <ul className="buylist">
                      <li>
                        <label>Additional Information</label>
                      </li>

                      <p class="inputdetailp mb15">
                        Amount
                        <span class="float-end">{noti.params.balanceLp}</span>
                      </p>
                      {noti.status != "failed" ? (
                        <p class="inputdetailp mb15">
                          Tx Fees
                          <span class="float-end">{noti.txn_fee}</span>
                        </p>
                      ) : null}
                    </ul>
                  ) : noti.task == "gift_miner" ? (
                    <ul className="buylist">
                      <li>
                        <label>Additional Information</label>
                      </li>

                      <p class="inputdetailp mb15">
                        To Address
                        <span class="float-end">
                          <a
                            target="_blank"
                            href={
                              "https://bscscan.com/address/" + noti.params.to
                            }
                          >
                            {" "}
                            {noti.params.to.substring(0, 5) +
                              "...." +
                              noti.params.to.substring(
                                noti.params.to.length - 7
                              )}
                          </a>
                        </span>
                      </p>

                      {noti.status != "failed" ? (
                        <p class="inputdetailp mb15">
                          Tx Fees
                          <span class="float-end">{noti.txn_fee}</span>
                        </p>
                      ) : null}
                    </ul>
                  ) : noti.task == "swapTokenforToken" ? (
                    <ul className="buylist">
                      <li>
                        <label>Additional Information</label>
                      </li>

                      <p class="inputdetailp mb15">
                        From Amount
                        <span class="float-end">
                          {noti.params.fromAmount == null
                            ? "0.000"
                            : parseFloat(noti.params.fromAmount).toFixed(3)}
                        </span>
                      </p>
                      <p class="inputdetailp mb15">
                        To Amount
                        <span class="float-end">
                          {noti.params.toAmount == null
                            ? "0.000"
                            : parseFloat(noti.params.toAmount).toFixed(3)}{" "}
                          {noti.params.to}
                        </span>
                      </p>
                      {noti.status != "failed" ? (
                        <p class="inputdetailp mb15">
                          Tx Fees
                          <span class="float-end">{noti.txn_fee}</span>
                        </p>
                      ) : null}
                    </ul>
                  ) : noti.task == "addLiquidity" ? (
                    <ul className="buylist">
                      <li>
                        <label>Additional Information</label>
                      </li>

                      <p class="inputdetailp mb15">
                        BUSD Amount
                        <span class="float-end">
                          {parseFloat(noti.params.tokenAamnt).toFixed(3)} BUSD
                        </span>
                      </p>

                      <p class="inputdetailp mb15">
                        EGOLD Amount
                        <span class="float-end">
                          {parseFloat(noti.params.tokenBamnt).toFixed(3)} EGOLD
                        </span>
                      </p>
                      {noti.status != "failed" ? (
                        <p class="inputdetailp mb15">
                          Tx Fees
                          <span class="float-end">{noti.txn_fee}</span>
                        </p>
                      ) : null}
                    </ul>
                  ) : noti.task == "claimFreeminer" ? (
                    <>
                      {noti.status != "failed" ? (
                        <ul className="buylist">
                          <li>
                            <label>Additional Information</label>
                          </li>

                          <p class="inputdetailp mb15">
                            Tx Fees
                            <span class="float-end">{noti.txn_fee}</span>
                          </p>
                        </ul>
                      ) : null}
                    </>
                  ) : null
                ) : null}
              </>
            ) : null}
          </div>
          {loaded ?
            ""
            : noti.length != 0 && noti.status == "success" ? (
              <a
                class="btn-color-primary m-3 "
                style={{ cursor: "pointer" }}
                href={"https://bscscan.com/tx/" + noti.result}
                target="_blank"
              >
                View on BscScan
              </a>
            ) : null}
        </div>
      </div>
      <br />
    </>
  );
}
