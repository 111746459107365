import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import LoaderCard from "./LoaderCard/LoaderCard";
import ConfirmCard from "./LoaderCard/ConfirmCard";
import ErrorCard from "./LoaderCard/ErrorCard";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Input,
    InputGroup,
    InputGroupText,
} from "reactstrap";
import BackButton from "../components/Buttons/BackButton/BackButton";
import { invokeservice } from "../api/apiService";
import { FaRegPaste } from "react-icons/fa6";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
const Web3 = require("web3");
const web3 = new Web3(process.env.REACT_APP_RPC);

export default function ChangePassword() {
    const history = useNavigate();
    const [pweye, setPweye] = useState(true);
    const [otp, setOTP] = useState("");
    const [password, setPassword] = useState("");
    const [newpassword, setnewPassword] = useState("");
    const [conpassword, setconPassword] = useState("");
    const [pwlen, setpwlen] = useState(false);
    const [pwupper, setpwupper] = useState(false);
    const [pwlower, setpwlower] = useState(false);
    const [pwnumber, setpwnum] = useState(false);
    const [pwspecial, setpwspecial] = useState(false);
    const [errorMSG, seterrorMSG] = useState({ status: null, message: "" });
    const [processState, setprocessState] = useState({
        state: "...",
        data: null,
    });


    function checkText(text) {
        setnewPassword(text);

        if (text.length >= 8) {
            setpwlen(true)
        } else {
            setpwlen(false)
        }
        if (!/[a-z]/.test(text)) {
            setpwlower(false)
        } else {
            setpwlower(true)
        }
        if (!/[A-Z]/.test(text)) {
            setpwupper(false)
        } else {
            setpwupper(true)
        }
        if (!/\d/.test(text)) {
            setpwnum(false)
        } else {
            setpwnum(true)
        }
        if (!/[^a-zA-Z0-9]/.test(text)) {
            setpwspecial(false)
        } else {
            setpwspecial(true)
        }
    }

    async function ChangePassword() {

        const token = localStorage.getItem("accessToken");
        setprocessState({ state: "processing", data: null });
        if (pwlen && pwnumber && pwlower && pwupper && pwspecial) {
            const data = {
                password: password,
                newpassword: newpassword,
                newpwconfirm: conpassword,
                totp: otp
            };

            fetch("https://auth.egoldfarm.com/api/auth/changepw", {
                method: "PUT",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + String(token),
                },
                body: JSON.stringify(data),
            })
                .then((response) => response.json())
                .then((json) => {
                    if (json.status == "success") {
                        setprocessState({
                            state: "done",
                            data: "Password changed successfully",
                        });
                        setPassword("");
                        setconPassword("");
                        setnewPassword("");
                        setOTP("");
                    }
                    else if (json.status == "failed") {
                        setprocessState({
                            state: "error",
                            data: json.message,
                        });
                    } else {
                        setprocessState({
                            state: "error",
                            data: json.detail,
                        });
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        } else {
            setprocessState({
                state: "error",
                data: "Password did not match all criteria",
            });

        }
    }



    return (
        <main>
            <div class="mainsection">
                <div class="tabsec secpadding lightgrey-bg brtlr">
                    <BackButton Title="Change Password" ReRoute="buy" />
                    <div className="changepwsection">
                        <div style={{ padding: 10 }}>
                            <label for="downline_rank" class="form-label" style={{ color: "#2D3748", fontSize: 15 }}>
                                Current password
                            </label>
                            <InputGroup>
                                <Input
                                    className="withdrawinput"
                                    type="password"
                                    name="pw"
                                    style={{
                                        backgroundColor: "#fff",
                                        border: " 0.489247px solid #dee2e6",

                                        color: "#000",
                                        fontSize: "13px",
                                        padding: "13px 12px",
                                        borderRadius: "8px",
                                    }}
                                    value={password}
                                    id="pw"
                                    onChange={(pw) => {
                                        setPassword(pw.target.value);
                                    }}
                                    required
                                />
                            </InputGroup>
                        </div>
                        <div style={{ marginTop: 5, padding: 10 }}>
                            <label for="downline_rank" class="form-label" style={{ color: "#2D3748", fontSize: 15 }}>
                                New password
                            </label>
                            <InputGroup>
                                <Input
                                    className="withdrawinput"
                                    type="password"
                                    name="nwpw"
                                    id="nwpw"
                                    value={newpassword}
                                    style={{
                                        backgroundColor: "#fff",
                                        border: " 0.489247px solid #dee2e6",

                                        color: "#000",
                                        fontSize: "13px",
                                        padding: "13px 12px",
                                        borderRadius: "8px",
                                    }}
                                    onChange={(password) => {
                                        checkText(password.target.value)

                                    }}
                                    required
                                />
                            </InputGroup>
                        </div>
                        <div style={{ marginTop: 5, padding: 10 }}>
                            <label for="downline_rank" class="form-label" style={{ color: "#2D3748", fontSize: 15 }}>
                                Confirm password
                            </label>
                            <InputGroup>
                                <Input
                                    className={errorMSG.status ? "passwordboxerror" : "passwordbox"}
                                    type={pweye ? "password" : "text"}
                                    name="amount"
                                    id="email"
                                    value={conpassword}
                                    onChange={(pw) => {
                                        setconPassword(pw.target.value);
                                        pw.target.value == newpassword ? seterrorMSG({ status: false, message: " " }) : seterrorMSG({ status: true, message: "Password does not match" })
                                    }}
                                />
                                <InputGroupText
                                    className={"inputicon"}
                                    onClick={() => {
                                        setPweye(!pweye)

                                    }}
                                >
                                    {pweye ? <AiFillEyeInvisible color='#4F6B75' size={24} /> : <AiFillEye color='#4F6B75' size={24} />}

                                </InputGroupText>
                            </InputGroup> {errorMSG.status ? (
                                <p className="errorbox">{errorMSG.message}</p>
                            ) : null}

                        </div>
                        <div style={{ marginTop: 5, padding: 10 }}>
                            <label for="downline_rank" class="form-label" style={{ color: "#2D3748", fontSize: 15 }}>
                                2FA
                            </label>
                            <InputGroup>
                                <Input
                                    className="passwordbox"
                                    type="text"
                                    name="otp"
                                    id="otp"
                                    placeholder="Enter 2FA code"
                                    value={otp}
                                    onChange={(otp) => {
                                        setOTP(otp.target.value);
                                    }}
                                />
                                <InputGroupText
                                    className={"inputicon"}
                                    onClick={async () => {
                                        setOTP(await navigator.clipboard.readText());
                                    }}
                                >
                                    <FaRegPaste color='black' size={22} />

                                </InputGroupText>
                            </InputGroup>

                        </div>
                        <div style={{ marginTop: 20, padding: 10, marginBottom: 20 }}>
                            <button className='btnlogin' onClick={() => {
                                ChangePassword()
                            }}>
                                Submit
                            </button>

                        </div>
                        <div style={{
                            marginTop: 40, border: " 1px solid #E2E8F0",
                            padding: "25px 10px 20px 10px",
                            textAlign: "left",
                            borderRadius: 4,
                            marginBottom: 20
                        }}>
                            <span>
                                <p style={{ textAlign: "left", fontSize: 14, fontWeight: 500, color: "#232325", marginBottom: 20 }}>
                                    Your password must include :
                                </p>
                                <p className="pwcriterias">
                                    <BsFillCheckCircleFill color={pwlen ? "#648795" : "#C1CFD5"} size={20} /> A minimum of 8 characters
                                </p>
                                <p className="pwcriterias">
                                    <BsFillCheckCircleFill color={pwupper ? "#648795" : "#C1CFD5"} size={20} />  At least one uppercase character
                                </p>
                                <p className="pwcriterias">
                                    <BsFillCheckCircleFill color={pwlower ? "#648795" : "#C1CFD5"} size={20} />  At least one lowercase character
                                </p>
                                <p className="pwcriterias">
                                    <BsFillCheckCircleFill color={pwnumber ? "#648795" : "#C1CFD5"} size={20} />  At least one number character
                                </p>
                                <p className="pwcriterias">
                                    <BsFillCheckCircleFill color={pwspecial ? "#648795" : "#C1CFD5"} size={20} />  At least one special character. <br />eg : !,@,#,$,%,^,&,*
                                </p>
                            </span>
                        </div>
                        {processState.state == "..." ? (
                            ""
                        ) : processState.state == "processing" ? (
                            <LoaderCard />
                        ) : processState.state == "done" ? (
                            <div className="convertsionbox">
                                <ConfirmCard tx={processState.data} />
                            </div>
                        ) : (
                            <div className="convertsionbox">
                                <ErrorCard err={processState.data} />
                            </div>
                        )}

                    </div>

                </div>

            </div>
        </main>
    );
}
