import React, { useState } from "react";
import TreeModal from "./AppRefTreeModal";
import dataVal from "../data/Abis.json";

const Web3 = require("web3");
const web3 = new Web3(process.env.REACT_APP_RPC);
const CONTRACT_ADDRESS = process.env.REACT_APP_INDENTITY_ADDR;
const contractInstance = new web3.eth.Contract(
  dataVal.identityabi,
  CONTRACT_ADDRESS
);
var init = 0;

//to not round values while showing balance
function noround(number, decimalDigits) {
  const powerOfTen = Math.pow(10, decimalDigits);
  const formattedNumber = Math.floor(number * powerOfTen) / powerOfTen;
  if (Number.isInteger(formattedNumber)) {
    return roundzeroes(formattedNumber, 2);
  } else return formattedNumber;
}

//to round decimal points with zeroes
function roundzeroes(val, x) {
  var float = parseFloat(val).toFixed(18);
  var num = float.toString();
  var n = num.slice(0, num.indexOf(".") + (x + 1));
  return n;
}
function numFormatter(num) {
  if (num > 999 && num < 1000000) {
    return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
  } else if (num > 1000000) {
    return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
  } else if (num < 1000) {
    var x = noround(num, 2);
    return x; // if value < 1000, nothing to do
  }
}

const test = (val, addChild, pro, sales, sfn, tokdata) => {
  return val.map((val) => (
    <ChildComponent
      key={val}
      addChild={addChild}
      data={pro}
      id={val}
      sales={sales}
      sfn={sfn}
      tokdata={tokdata}
    />
  ));
};

const InitComponent = (props) => {
  const [modalShow, setmodalShow] = useState(false);
  let x = props.data;
  if (!init) {
    props.sales(x);
    init = 1;
  }
  return (
    <li>
      <input type="checkbox" id={"ch" + x} onClick={(e) => props.addChild(x)} />
      <label class="tree_label" for={"ch" + x}>
        <span
          onClick={(e) => {
            setmodalShow(true);
          }}
        >
          {x ? x.slice(0, 5) + "....." + x.slice(-5) : "..."}
        </span>
        <span className="salesspan">
          {" "}
          {isNaN(props.state.levelsData[x])
            ? "..."
            : parseInt(props.state.levelsData[x])}
        </span>
      </label>
      <ul>
        {props.state.treeData[x]
          ? test(
              props.state.treeData[x],
              props.addChild,
              props.state.treeData,
              props.state.salesData,
              props.sales,
              props.state.levelsData
            )
          : ""}
      </ul>
      <TreeModal
        show={modalShow}
        address={x}
        close={() => setmodalShow(false)}
      />
    </li>
  );
};

const ChildComponent = (props) => {
  const [modalShow, setmodalShow] = useState(false);
  let v = props.tokdata;
  if (!v.hasOwnProperty(props.id)) {
    props.sfn(props.id);
  }
  return (
    <li>
      <input
        type="checkbox"
        id={"ch" + props.id}
        onClick={(e) => props.addChild(props.id)}
      />
      <label class="tree_label" for={"ch" + props.id}>
        <span
          className="wadd"
          onClick={(e) => {
            setmodalShow(true);
          }}
        >
          {props.id.slice(0, 5) + "....." + props.id.slice(-5)}
        </span>
        <span className="salesspan">
          {" "}
          {isNaN(props.tokdata[props.id])
            ? "..."
            : parseInt(props.tokdata[props.id])}
        </span>
      </label>
      <ul>
        {props.data[props.id]
          ? test(
              props.data[props.id],
              props.addChild,
              props.data,
              props.sales,
              props.sfn,
              props.tokdata
            )
          : ""}
      </ul>
      <TreeModal
        show={modalShow}
        address={props.id}
        close={() => setmodalShow(false)}
      />
    </li>
  );
};

class TreeComponent extends React.Component {
  state = {
    treeData: {},
    salesData: {},
    levelsData: {},
  };

  onAddChild = (val) => {
    if (!this.state.treeData[val]) {
      fetch(
        `https://egold-treasury-api.herokuapp.com/v1/summary/dashboard/${val}`
      )
        .then((response) => response.json())
        .then((data) => {
          let st = this.state.treeData;
          if (!st.hasOwnProperty(data._id)) {
            if (data.children) {
              this.setState({
                treeData: {
                  ...this.state.treeData,
                  [data._id]: data.children,
                },
              });
            } else
              this.setState({
                treeData: { ...this.state.treeData, [data._id]: 0 },
              });
          }
        });
    }
  };

  fetchsales = (val) => {
    var getthis = this;
    contractInstance.methods
      .fetchUser(val)
      .call()
      .then(async function (res) {
        let lv = getthis.state.levelsData;
        if (!lv.hasOwnProperty(val)) {
          getthis.setState({
            levelsData: {
              ...getthis.state.levelsData,
              [val]: parseInt(res[1]),
            },
            salesData: {
              ...getthis.state.salesData,
              [val]: parseFloat(web3.utils.fromWei(res[2], "ether")).toFixed(2),
            },
          });
        }
      });
  };

  render() {
    return (
      <>
        <ul class="tree">
          <InitComponent
            addChild={this.onAddChild}
            data={localStorage.getItem("acct")}
            state={this.state}
            sales={this.fetchsales}
          ></InitComponent>
        </ul>
      </>
    );
  }
}

export default TreeComponent;
